import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Progress from '../../modules/Progress';
import { IMG_URL } from '../../../../config/config';

export class PreparationCloth extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="preparation-cloth">
        <section className="bodygram-header">
          <Progress process={3} />
          <p className="bodygram-header-intro">おむつサイズ診断</p>
          <h1 className="bodygram-header-title"><span className="under-line">3.計測</span></h1>
          <p className="bodygram-header-desc">からだを正確に計測するために、①服装②撮影場所③カメラの位置を確認しましょう。</p>
        </section>
        <section className="bodygram-content">
          <ol className="preparation-image-list">
            <li className="preparation-image-item">
              <h3 className="preparation-title">服装</h3>
              <div className="wrap-preparation-image">
                <div>
                  <img className="uc-cloth" src={`${IMG_URL}images/UC_cloth.png`} alt="uc_cloth" />
                </div>
                <ul>
                  <li><p>帽子、サングラス、マスクは外す</p></li>
                  <li><p>長い髪は束ねる</p></li>
                </ul>
              </div>
              <div className="wrap-preparation-image">
                <div>
                  <img className="uc-standing" src={`${IMG_URL}images/UC_standing.png`} alt="uc_standing" />
                </div>
                <ul>
                  <li><p>可能な限りシャツなどはズボンにイン</p></li>
                  <li><p>上着類は脱ぐ</p></li>
                  <li><p>できるだけ重ね着をせず、体のラインが分かりやすい服装</p></li>
                  <li><p>長袖は腕をまくり、手首をみせる</p></li>
                  <li><p>ハイヒールや厚底の靴を避け、足先または靴が見えるように</p></li>
                </ul>
              </div>
              <div className="wrap-preparation-image">
                <h6 className="prearation-title-longskirt">適さない服装</h6>
                <div>
                  <img className="uc-longskirt" src={`${IMG_URL}images/UC_longskirt.png`} alt="uc_longskirt" />
                </div>
                <ul>
                  <li><p>全体に柄がついた服</p></li>
                  <li><p>体のラインが分かりにくいロングスカート、ワイドパンツ、ルーズシャツなど、または大きなサイズの服</p></li>
                </ul>
              </div>
            </li>
          </ol>
          <Link to='preparation-shoot'>
            <button className="btn btn-submit">次へ</button>
          </Link>
        </section>
      </div>
    )
  }
}

export default PreparationCloth
