import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Button from "../modules/Button";
import APIService from "../../service/api";
import { routes } from "../../routers";
import {
  BOT_LINK,
  HEADER_COLOR,
  IMG_URL,
  IS_WEB,
  LOGO,
} from "../../../config/config";
import { searchToObject } from "../../service/utils";
import {
  DEFAULT_STORE,
  DEFAULT_BACKGROUND_COLOR,
  NOT_DEFAULT_BACKGROUND_COLOR,
  SURVEY_ROUTES,
  WEB_SURVEY_ROUTES,
  STORES_HAS_EXCLUDED_STYLE,
  SUGI_BACKGROUND_COLOR,
  STORES_WITHOUT_PRODUCT_LINKS,
  STORES_HAS_LIMITED_MAX_HEIGHT
} from "../../../constants/global";

const DEFAULT_LOGO_FILE = "images/logo.png";
const DEFAULT_HEADER_BACKGROUND_COLOR = "#fff";

export class Header extends Component {
  API = new APIService();

  constructor(props) {
    super(props);
    this.state = {
      orientation: "Portrait",
      logo: "",
      backgroundColor: "",
      isWeb: true,
    };
  }

  componentDidMount() {
    this.checkOrientation();
    this.getLogo();
    this.handEvents();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  checkOrientation = () => {
    const orientation =
      window.innerWidth > window.innerHeight ? "Landscape" : "Portrait";
    this.setState({
      orientation,
    });
  };

  handEvents = () => {
    window.addEventListener("resize", () => {
      this.checkOrientation();
    });
    this.unlisten = this.props.history.listen((location) => {
      const areWebSurveyRoutes = Object.values(WEB_SURVEY_ROUTES).includes(
        location.pathname
      );

      const hasStore = location.search.includes("store");
      const { store } = searchToObject(location.search);
      const appEl = document.getElementById("app");
      appEl.style.backgroundColor =
        hasStore && areWebSurveyRoutes
          ? STORES_WITHOUT_PRODUCT_LINKS.includes(store || "")
            ? SUGI_BACKGROUND_COLOR
            : NOT_DEFAULT_BACKGROUND_COLOR
          : DEFAULT_BACKGROUND_COLOR;
      this.setState({
        logo:
          (hasStore && areWebSurveyRoutes && localStorage.getItem(LOGO)) ||
          DEFAULT_LOGO_FILE,
        backgroundColor:
          (hasStore &&
            areWebSurveyRoutes &&
            localStorage.getItem(HEADER_COLOR)) ||
          DEFAULT_HEADER_BACKGROUND_COLOR,
      });
    });
  };

  getLogo = () => {
    const { store, isWeb } = searchToObject(this.props.location.search);
    let isCurrentWeb =
      isWeb ||
      localStorage.getItem(IS_WEB) ||
      Object.values(SURVEY_ROUTES)
        .concat("/") // "/" redirect to "web-survey"
        .includes(window.location.pathname);
    if (["/introduction"].includes(window.location.pathname) && !isWeb) {
      isCurrentWeb = false;
    }
    this.setState({
      isWeb: isCurrentWeb,
    });
    if (store) {
      if (store !== DEFAULT_STORE) {
        const appEl = document.getElementById("app");
        appEl.style.backgroundColor = STORES_WITHOUT_PRODUCT_LINKS.includes(
          store
        )
          ? SUGI_BACKGROUND_COLOR
          : NOT_DEFAULT_BACKGROUND_COLOR;
      }
      this.API.get(`api/v1/web/stores?store=${store}`)
        .then((res) => {
          if (res.data) {
            const { logo, head_color } = res.data;
            localStorage.setItem(LOGO, logo);
            localStorage.setItem(HEADER_COLOR, head_color);
            this.setState({
              logo: logo,
              backgroundColor: head_color,
            });
          }
        })
        .catch(() => {});
    } else {
      this.setState({
        logo: DEFAULT_LOGO_FILE,
        backgroundColor: DEFAULT_HEADER_BACKGROUND_COLOR,
      });
    }
  };

  goBack = () => {
    this.props.location.pathname === "/introduction"
      ? this.goToLineBot()
      : this.props.history.goBack();
  };

  goToLineBot = () => {
    if (BOT_LINK.match(/^https?:\/\//)) {
      window.location.href = BOT_LINK;
    }
  };

  redirectToStart = () => {
    const isWeb = localStorage.getItem(IS_WEB);
    this.props.history.push(`/introduction?${isWeb ? "isWeb=true" : ""}`);
  };

  render() {
    const { orientation, logo, backgroundColor, isWeb } = this.state;
    const { store } = searchToObject(this.props.location.search);
    const { pathname } = this.props.location;
    const typeHeader =
      routes.find((item) => item.path === pathname) &&
      routes.find((item) => item.path === pathname).header;
    const headerCamera = typeHeader && typeHeader.type === "camera";
    const headerTransparent =
      typeHeader &&
      typeHeader.isTransparent &&
      (typeHeader.isTransparentPortrait || orientation == "Portrait");
    const headerOfCamera = (
      <>
        <Button
          className={`border-0 line-height-0 btn-back ${
            headerTransparent ? "" : "bg-transparent"
          }`}
          content={`<img src="${IMG_URL}images/btn-back.png" alt="back-button">`}
          isFreeClick={true}
          onClick={this.goBack}
        />
        <Button
          className={`header-camera-logo position-center border-0 bg-transparent ${
            headerTransparent ? "d-none" : ""
          }`}
          content={`<h1 class="header-title"><span class="under-line">3.計測</span></h1>`}
        />
        <Button
          className={`btn-right border-0 line-height-0 ${
            headerTransparent ? "" : "bg-transparent"
          }`}
          content={`<span class="font-weight-bold blue">中断する</span>`}
          onClick={this.redirectToStart}
          deplayClick={2000}
        />
      </>
    );
    const areWebSurveyRoutes = Object.values(WEB_SURVEY_ROUTES).includes(
      window.location.pathname
    );
    const headerDefault = (
      <>
        <Button
          className={`border-0 bg-transparent line-height-0 btn-back ${
            isWeb ? "d-none" : ""
          }`}
          content={`<img src="${IMG_URL}images/btn-back.png" alt="back-button">`}
          isFreeClick={true}
          onClick={this.goBack}
        />
        <Button
          className="header-logo position-center border-0 bg-transparent"
          content={`<img class="${
            STORES_HAS_EXCLUDED_STYLE.includes(store)
              ? "h-auto mx-initial"
              : STORES_HAS_LIMITED_MAX_HEIGHT.includes(store)
              ? "mt-1 h-auto max-height-100"
              : ""
          }" src="${logo ? `${IMG_URL}${logo}` : ""}">`}
        />
        <Button
          className={`btn-right border-0 line-height-0 bg-transparent ${
            isWeb ? "d-none" : ""
          }`}
          content={`<img src="${IMG_URL}images/btn-back-to-line.svg" alt="back-to-line-button">`}
          onClick={this.goToLineBot}
          deplayClick={2000}
        />
      </>
    );

    let header = headerDefault;
    if (typeHeader === "none") {
      header = <></>;
    } else if (headerCamera) {
      header = headerOfCamera;
    }

    return (
      <div
        style={{
          backgroundColor:
            typeHeader !== "none" && !headerCamera && backgroundColor,
        }}
        className={`${
          headerCamera && headerTransparent
            ? "header-page-camera"
            : "header-page is-relative"
        } ${areWebSurveyRoutes ? "is-fixed" : "shadow"}`}
      >
        {header}
      </div>
    );
  }
}

export default withRouter(Header);
