import React, { Component } from 'react';
import { BODY_SIZE_METHOD, IMG_URL } from '../../../../config/config';
import Progress from '../../modules/Progress';

export class PreparationShoot extends Component {

  constructor(props) {
    super(props);
    this.state = {
      method: ''
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const method = localStorage.getItem(BODY_SIZE_METHOD)
    this.setState({ method: method || 'SelfTakePhoto' });
  }

  handleSubmit = () => {
    this.props.history.push('sound-settings')
  }

  render() {
    const selfTakePhotoGroup =
      <>
        <div className="wrap-preparation-image">
          <div>
            <img className="uc-desk" src={`${IMG_URL}images/UC_desk.png`} alt="uc_desk" />
          </div>
          <ul>
            <li><p>カメラは、対象者の腰の高さくらいに設置する</p></li>
            <li><p>スマートフォンを立てかけ、水平・垂直に固定できるように置く</p></li>
          </ul>
        </div>
        <div className="wrap-preparation-image">
          <div>
            <img className="uc-picturing" src={`${IMG_URL}images/UC_picturing.png`} alt="uc_picturing" />
          </div>
          <ul>
            <li><p>撮影ボタンを押しスマートフォンから離れ、画面内の人型に姿勢を合わせる</p></li>
          </ul>
        </div>
      </>
    const takePhotoByOther =
      <>
        <div className="wrap-preparation-image">
          <div>
            <img className="uc-picturing-by-other" src={`${IMG_URL}images/UC_picturing_by_other.png`} alt="uc_picturing_other" />
          </div>
          <ul>
            <li><p>撮影者は対象者と距離をとって、対象者の腰〜へその高さにカメラレンズがくるよう垂直に、スマートフォンを構える</p></li>
            <li><p>対象者を画面内の人型に姿勢を合わせるように立たせ、撮影をする</p></li>
          </ul>
        </div>
      </>
    return (
      <div className="preparation-shoot">
        <section className="bodygram-header">
          <Progress process={3} />
          <p className="bodygram-header-intro">おむつサイズ診断</p>
          <h1 className="bodygram-header-title"><span className="under-line">3.計測</span></h1>
        </section>
        <section className="bodygram-content">
          <ol className="preparation-image-list">
            <li className="preparation-image-item hidden mb-0 h-0"></li>
            <li className="preparation-image-item">
              <h3 className="preparation-title">撮影場所</h3>
              <div className="wrap-preparation-image">
                <div>
                  <img className="uc-cellphone" src={`${IMG_URL}images/UC_cellphone.png`} alt="uc_cellphone" />
                </div>
                <ul>
                  <li><p>暗所、逆光を避ける</p></li>
                  <li><p>対象者以外の人、人型のものが写りこまないようにする</p></li>
                </ul>
              </div>
            </li>
            <li className="preparation-image-item">
              <h3 className="preparation-title">カメラの位置</h3>
              {
                this.state.method === 'SelfTakePhoto' ? selfTakePhotoGroup : takePhotoByOther
              }
            </li>
          </ol>
          <button className="btn btn-submit" onClick={() => this.handleSubmit()}>撮影開始</button>
        </section>
      </div>
    )
  }
}

export default PreparationShoot
