export const USER_MEASUREMENT = 'USER_MEASUREMENT';
export const ESTIMATION_ID = 'ESTIMATION_ID';
export const JWT_TOKEN = 'TOKEN';
export const GENDER = 'GENDER';
export const UUID = 'UUID';
export const USER_ID = 'USER_ID';
export const BOT_LINK = `https://line.me/R/ti/p/${process.env.MIX_BOT_BASIC_ID}`;
export const BODY_SIZE_METHOD = 'BODY_SIZE_METHOD';
export const WAIST_MEASUREMENT = 'WAIST_MEASUREMENT';
export const STATE = 'STATE';
export const IS_WEB = 'IS_WEB';
export const LOGO = 'LOGO';
export const HEADER_COLOR = 'HEADER_COLOR';
export const IMG_URL = process.env.MIX_IMG_URL;
export const TAKE_PHOTO_BY_OTHER = 'TakePhotoByOther';
export const GA_TRACKING_CODE = process.env.MIX_REACT_APP_GA_TRACKING_CODE;
export const LIFF_ID = process.env.MIX_LIFF_ID;

// Config format input
export const NUMBER_FORMAT = {
  numeral: true,
  numeralPositiveOnly: true,
  numeralDecimalScale: 0,
  numeralThousandsGroupStyle: 'none',
}
