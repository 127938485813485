import React, { Component } from "react";
import { render } from "react-dom";
import liff from "@line/liff";
import Button from "./Button";
import { SURVEY_ROUTE } from "../../../constants/global";

let resolve;

export default class Modal extends Component {
  mounted = false;

  static create() {
    const containerElement = document.createElement("div");
    document.body.appendChild(containerElement);
    return render(<Modal />, containerElement);
  }

  constructor(props) {
    super(props);
    this.state = {
      message: "",
      option: {},
    };
  }

  handleConfirm = (isSurveyRoute = false) => {
    if (isSurveyRoute && this.state.option?.status === 412) {
      liff.closeWindow();
    }
    resolve(true);
    this.close();
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  show = (message, option = {}) => {
    this.mounted && this.setState({ message, option });
    return new Promise((res) => {
      resolve = res;
    });
  };

  close = () => {
    this.mounted && this.setState({ message: "" });
  };

  render() {
    const { message, option } = this.state;
    const isSurveyRoute = window.location.pathname === SURVEY_ROUTE.survey;

    return (
      <>
        {message && (
          <div className="error-box">
            <div className="error-content font-weight-bold">
              <h2 className="msg-title txt-center">
                {option?.title || "エラー"}
              </h2>
              <p
                className={`msg-content txt-center ${
                  isSurveyRoute ? "fs-18" : ""
                }`}
              >
                {message}
              </p>
              <Button
                className="btn"
                content="OK"
                onClick={() => this.handleConfirm(isSurveyRoute)}
              ></Button>
            </div>
          </div>
        )}
      </>
    );
  }
}
