import React, { Component } from 'react'
import { IMG_URL } from '../../../config/config';

export class BodyGramMark extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="bodygram-splash">
        <img src={`${IMG_URL}images/bodygram-logo.png`} alt="img bodygram-splash"/>
      </div>
    )
  }
}

export default BodyGramMark
