import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import APIService from "../../service/api";
import messageService from "../../service/messageService";
import store from "../../service/store";
import Progress from "../modules/Progress";
import { searchToObject } from "../../service/utils";
import {
  BOT_LINK,
  JWT_TOKEN,
  IMG_URL,
  USER_MEASUREMENT,
  ESTIMATION_ID,
  UUID,
  IS_WEB,
  BODY_SIZE_METHOD,
} from "../../../config/config";
import cameraService from "../../service/cameraService";
import {
  DEFAULT_STORE,
  STORES_WITHOUT_PRODUCT_LINKS,
} from "../../../constants/global";

const MESSAGE_ERROR_401 = "再度ログインしてください";
const SIZE_FREE = "こちらの商品は\nフリーサイズです";

export class Result extends Component {
  api = new APIService();
  resultMeasurement =
    store["resultMeasurement"] ||
    JSON.parse(localStorage.getItem("resultMeasurement") || "{}");
  userMeasurement =
    store[USER_MEASUREMENT] ||
    JSON.parse(localStorage.getItem(USER_MEASUREMENT));
  jwt_token = localStorage.getItem(JWT_TOKEN);
  // user_id = localStorage.getItem(USER_ID) || 'U4d9572a98baaddc5c2f543185b20ee1d'

  constructor(props) {
    super(props);
    this.state = {
      products: [],
      waist: null,
      isOverSize: false,
      isApiError: false,
      bodySizeMethod: localStorage.getItem(BODY_SIZE_METHOD) || "SelfTakePhoto",
      store: "",
    };
  }

  componentDidMount() {
    this.detectStore();
    this.fetchProductsByUserID();
  }

  componentWillUnmount() {
    messageService.close();
  }

  detectStore = () => {
    const { store } = searchToObject(this.props.location.search);
    if (store && store !== DEFAULT_STORE) {
      this.setState({ store });
    }
  };

  callAPIUserLogs = (
    res = {},
    belly_waist_girth,
    isFromWaistSeftInput = false
  ) => {
    const estimation_id = localStorage.getItem(ESTIMATION_ID);
    const uuid = localStorage.getItem(UUID, uuid);
    let userLogParams = {
      product_name: res?.data?.result_1[0]?.product_sub_name || null,
      product_size: res?.data?.result_1[0]?.product_size || "oversize",
      height: null,
      weight: null,
      estimate_id: null,
      waist: null,
      waist_input: null,
      tigh: null,
      hip: null,
      uuid,
    };
    // Save activity logs into Sever
    // Check case have waist (from BD16) or come from BD14
    if (isFromWaistSeftInput) {
      // Case come from BD16 (seft input waist)
      userLogParams = {
        ...userLogParams,
        waist_input: belly_waist_girth || null,
      };
    } else {
      // Case come from BD14 (Bodygram analysis)
      userLogParams = {
        ...userLogParams,
        height: this.userMeasurement?.height || null,
        weight: this.userMeasurement?.weight || null,
        estimate_id: estimation_id || null,
        tigh: Math.round(this.resultMeasurement.thigh_girth_r / 10) || null,
        hip: Math.round(this.resultMeasurement.hip_girth / 10) || null,
        waist: belly_waist_girth || null,
      };
    }
    const isWeb = localStorage.getItem(IS_WEB);
    this.api.post(
      `api/v1/${isWeb ? "web/guests" : "users"}/logs`,
      userLogParams,
      {
        headers: {
          Authorization: `Bearer ${this.jwt_token}`,
        },
      }
    );
  };

  fetchProductsByUserID = async () => {
    // Check userId
    if (!this.jwt_token) {
      await messageService.show(MESSAGE_ERROR_401);
      if (BOT_LINK.match(/^https?:\/\//)) {
        window.location.href = BOT_LINK;
      }
      return;
    }
    const { waist } = searchToObject(this.props.location.search);
    let belly_waist_girth = 70;
    if (waist) {
      belly_waist_girth = waist;
      this.setState({
        waist,
      });
    } else if (this.resultMeasurement.belly_waist_girth) {
      belly_waist_girth = Math.round(
        this.resultMeasurement.belly_waist_girth / 10
      );
    }
    // Block if belly_waist_girth > 125
    if (belly_waist_girth > 125) {
      this.setState({
        isOverSize: true,
      });
      this.callAPIUserLogs(undefined, belly_waist_girth, !!waist);
      return;
    }
    const isWeb = localStorage.getItem(IS_WEB);
    const uuid = localStorage.getItem(UUID);
    this.api
      .post(
        `api/v1/${isWeb ? "web/" : ""}products/size`,
        { belly_waist_girth, ...(uuid ? { uuid } : {}) },
        {
          headers: {
            Authorization: `Bearer ${this.jwt_token}`,
          },
        }
      )
      .then((res) => {
        if (
          res.data &&
          res.data.result_1 &&
          res.data.result_1[0].product_size
        ) {
          this.setState({
            products: res.data || [],
          });
        } else {
          this.setState({
            isOverSize: true,
          });
        }
        this.callAPIUserLogs(res, belly_waist_girth, !!waist);
      })
      .catch(() => {
        this.setState({
          isApiError: true,
        });
      });
  };

  renderProduct(product, index, products) {
    return (
      <div key={index}>
        <div className="wrap-box">
          <div
            className={`product-title blue ${
              product.product_size === SIZE_FREE ? "size-free" : ""
            }`}
          >
            <div className="title-name">
              <span>最適サイズ</span>
            </div>
            <div
              className={`title-size ${
                product.product_size.length > 10 ? "" : "txt-center"
              }`}
            >
              {product.product_size.length > 10 ? (
                <span className="title-size-content">
                  {product.product_size}
                </span>
              ) : (
                <>
                  <span className="big-text">{product.product_size}</span>
                  <span className="d-inline-block">サイズ</span>
                </>
              )}
            </div>
          </div>
          <div className="product-content">
            <div className="mt-4 d-flex justify-content-between">
              <div className="content-label">
                <h5 className="product-pant">{product?.product_type}</h5>
                <p>{product.product_name}</p>
                <p className="content-info font-weight-bold pre-wrap">
                  {product.product_sub_name}
                </p>
              </div>
              <div className="content-wrapper">
                <img
                  className="content-image"
                  src={product.product_image}
                  alt="product image"
                />
              </div>
            </div>
            <div className="mt-3 txt-center">
              <a
                className={`btn ${
                  STORES_WITHOUT_PRODUCT_LINKS.includes(this.state.store)
                    ? "d-none-imp"
                    : ""
                } d-flex justify-content-center align-items-center`}
                href={product.product_link}
              >
                詳しく見る
              </a>
            </div>
          </div>
        </div>
        <div
          className={`wrap-icon-img ${
            index === products.length - 1 ? "d-none" : ""
          }`}
        >
          <img
            className="img-plus"
            src={`${IMG_URL}images/plus-big.png`}
            alt=""
          />
        </div>
      </div>
    );
  }

  renderNote() {
    return (
      <>
        {this.state.isOverSize ? (
          <>
            <p className="result-note mt-4">
              申し訳ありません、ご提案させていただいた商品ではぴったりなサイズが見つかりませんでした。
            </p>
            <p className="result-note">
              「うまく撮影できなかったかも？」と思う方は、撮影のポイントを確認して、撮り直しをしてみましょう。
            </p>
          </>
        ) : (
          <>
            <div className="wrap-icon-img">
              <img src={`${IMG_URL}images/result_arrow_down.png`} />
            </div>
            <a
              href="https://map.unicharm.co.jp/"
              target="_blank"
              className="map-link"
            >
              <img src={`${IMG_URL}images/store-icon.svg`} alt="store icon" />
              商品がある店舗をマップで探す
            </a>
            <h3 className="txt-center">
              <span className="result-text">商品購入のポイント</span>
            </h3>
            <div className="result_point">
              <img
                className="result-img"
                src={`${IMG_URL}images/result_note.png`}
              />
              <p className="result-note">
                メモを取っていただくか、お店でこの画面をご提示いただくと、商品選びがスムーズになります。
              </p>
            </div>
            <div className="line-through"></div>
            <p className="result-note">
              最適サイズ診断はいかがでしたか？「この結果は合っているのかな？」と気になる方は、撮影をやり直すこともできます。
            </p>
          </>
        )}
        <h3 className="txt-center mt-5">
          <span className="result-text">撮影のポイントを再確認</span>
        </h3>
        <div className="result_point">
          <img className="result-img" src={`${IMG_URL}images/cloth_way.png`} />
          <p className="result-note">
            身体のラインが分かりやすい服装、手首が見える状態で撮影していますか？
          </p>
        </div>
        <div className="result_point">
          <img
            className="result-img"
            src={`${IMG_URL}images/banned_accessory.png`}
          />
          <p className="result-note">
            マスク、帽子、サングラスなどは外し、長い髪は束ねて撮影していますか？
          </p>
        </div>
        <div className="result_point">
          <img
            className="result-img"
            src={`${IMG_URL}images/taking_condition.png`}
          />
          <p className="result-note">
            暗所や逆光、服や靴と同色の背景を避けた場所で撮影していますか？
          </p>
        </div>
        <div
          onClick={async () =>
            await cameraService.show(this.state.bodySizeMethod)
          }
          className={`camera-guide-wrapper flex-centered ${
            this.state.bodySizeMethod === "ManuallyEnter" ? "d-none-imp" : ""
          }`}
        >
          <img src={`${IMG_URL}images/camera-ic.svg`} alt="camera icon" />
          <span className="camera-label">詳しい撮影ガイドを見る</span>
        </div>
        <Link to="body-size-get-method">
          <div className="btn d-flex justify-content-center align-items-center mt-5 py-2 mb-3">
            <span className="font-weight-bold">診断をやり直す</span>
          </div>
        </Link>
      </>
    );
  }

  render() {
    const { products, isOverSize, isApiError, waist } = this.state;
    // Has waist is self input waist
    const thigh_girth_r = waist
      ? "-cm"
      : `${Math.round(this.resultMeasurement.thigh_girth_r / 10) || "-"}cm`;
    const belly_waist_girth = waist
      ? `${waist}cm`
      : `${Math.round(this.resultMeasurement.belly_waist_girth / 10) || "-"}cm`;
    const hip_girth = waist
      ? "-cm"
      : `${Math.round(this.resultMeasurement.hip_girth / 10) || "-"}cm`;
    const result_1 =
      products.result_1 &&
      products.result_1.map((product, index) =>
        this.renderProduct(product, index, products.result_1)
      );
    const result_2 =
      products.result_2 &&
      products.result_2.map((product, index) =>
        this.renderProduct(product, index, products.result_2)
      );

    const productEls = (
      <>
        {result_1}
        {result_2 && !!result_2.length && (
          <div
            className="txt-center blue"
            style={{
              fontSize: "1.125rem", // 18px
              paddingTop: "1.5rem", // 24px
              paddingBottom: "1.5rem", // 24px
            }}
          >
            または
          </div>
        )}
        {result_2}
      </>
    );

    return (
      <div className="result-page">
        <section>
          <Progress process={4} />
          <p className="title-sm">最適サイズ診断</p>
          <h1 className="title-page">5.診断結果</h1>
        </section>
        <section>
          <div className="wrap-box result-size">
            <div className="box-left">
              <ul className="result-size-list">
                <li className="font-weight-bold">計測結果</li>
                <li>太もも周り</li>
                <li>ウエスト（おへそ）周り</li>
                <li>ヒップ周り</li>
              </ul>
            </div>
            <div className="box-right">
              <ul>
                <li>
                  <span>&nbsp;</span>
                </li>
                <li>
                  <span className="blue result-size-text">{thigh_girth_r}</span>
                </li>
                <li>
                  <span className="blue result-size-text">
                    {belly_waist_girth}
                  </span>
                </li>
                <li>
                  <span className="blue result-size-text">{hip_girth}</span>
                </li>
              </ul>
            </div>
          </div>
          {isOverSize ? (
            <>
              {this.renderNote()}
              <div className="result-over-size">
                <section className="result-apology">
                  <p>
                    他の商品をお探しになる場合は、以下「ライフリーいきいきダイヤル」にお電話ください。
                  </p>
                  <p>
                    もしくは、ユニ・チャーム問い合わせページより、チャットやメールでのお問い合わせをご活用ください。
                  </p>
                </section>
                <section className="wrap-box live-dial">
                  <p className="title-dial">
                    <span>電話でのお問い合わせ</span>
                  </p>
                  <p className="dial-time">
                    <span>受付時間 10:00 ~ 16:00 (土日・祝日を除く)</span>
                  </p>
                  <p className="dial-phone">
                    <span className="wrap-phone-img">
                      <img src={`${IMG_URL}images/phone.png`} alt="" />
                    </span>
                    0120-0
                    <span className="phone-num">
                      4<span className="phone-text">よ</span>
                    </span>
                    <span className="phone-num">
                      1<span className="phone-text">い</span>
                    </span>
                    -
                    <span className="phone-num">
                      0<span className="phone-text">お</span>
                    </span>
                    <span className="phone-num">
                      6<span className="phone-text">む</span>
                    </span>
                    <span className="phone-num">
                      2<span className="phone-text">つ</span>
                    </span>
                  </p>
                  <a href="tel:0120041062">
                    <button className="btn btn-submit">電話をかける</button>
                  </a>
                </section>
                <section className="wrap-box live-dial">
                  <p className="title-dial label-orange">
                    <span>チャットでのお問い合わせ</span>
                  </p>
                  <p className="dial-time">
                    <span>受付時間 10:00 ~ 13:00　14:30 ~ 16:00</span>
                  </p>
                  <p className="dial-time">
                    <span>(土日・祝日を除く)</span>
                  </p>
                  <a href="https://www.unicharm.co.jp/ja/contact-us.html">
                    <button className="btn btn-submit">
                      チャットで問い合わせる
                    </button>
                  </a>
                </section>
                <section className="wrap-box live-dial">
                  <p className="title-dial label-yellow">
                    <span>メールでのお問い合わせ</span>
                  </p>
                  <p className="dial-time">
                    <span>受付時間 10:00 ~ 16:00 (土日・祝日を除く)</span>
                  </p>
                  <a href="https://www.support.unicharm.co.jp/mailcustomer/s/">
                    <button className="btn btn-submit">
                      メールで問い合わせる
                    </button>
                  </a>
                </section>
                <section className="request-mail">
                  <p>
                    ※
                    新型コロナウイルス感染症の影響により、多くのお問い合わせをいただいており、お電話が繋がりにくい状況になっております。
                  </p>
                  <p>
                    メールやチャットもご活用頂けますようお願い申し上げます。尚、当面の間はお電話の受付時間を上記に変更しております。
                  </p>
                  <p>尿もれは、誰にでも起こり得るものです。</p>
                  <p>
                    尿もれの仕組みや原因、みんなの対策や体験談を知って、自分なりの尿もれ対策のヒントにしましょう。
                  </p>
                  <a href="https://jp.lifree.com/ja/urine-leakage.html">
                    <button className="btn btn-submit mg-top-15">
                      ライフリー尿もれ対策サイトへ
                    </button>
                  </a>
                </section>
              </div>
            </>
          ) : (
            <>
              <div className="wrap-icon-img">
                <img
                  className="arrow-down-big-img"
                  src={`${IMG_URL}images/arrow-down-big.png`}
                  alt=""
                />
              </div>
              {productEls}
              {!isApiError && this.renderNote()}
            </>
          )}
        </section>
      </div>
    );
  }
}

export default withRouter(Result);
