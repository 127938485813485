import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import APIService from "../../service/api";
import { steps, DEFAULT_STORE } from "../../../constants/global";
import { IMG_URL, USER_ID } from "../../../config/config";
import { NO_INTERNET_MESSAGE } from "../../../config/message";
import { searchToObject } from "../../service/utils";
import messageService from "../../service/messageService";

export class WebSurvey extends Component {
  API = new APIService();

  constructor(props) {
    super(props);
    this.state = {
      store: "",
      isConfirmed: false,
      activeStep: 1,
      activeProgress: 0,
      steps,
      uuid: "",
      userId: "",
      isDisabled: false,
      lastStepId: null,
    };
  }

  componentDidMount() {
    this.getUserInfoAndQuestions();
  }

  getUserInfoAndQuestions = () => {
    const { store } = searchToObject(this.props.location.search);
    this.setState({ store: store || DEFAULT_STORE }, () => {
      this.getQuestions();
    });
  };

  getQuestions = () => {
    const { steps, store } = this.state;
    let userId = localStorage.getItem(USER_ID);
    if (userId) {
      if (!userId.includes(store)) {
        const lastHyphenIndex = userId.lastIndexOf("-");
        userId = userId.slice(0, lastHyphenIndex).concat(`-${store}`);
        localStorage.setItem(USER_ID, userId);
      }
    } else {
      userId = `U${uuidv4()}-${store}`; // U is optional char
      localStorage.setItem(USER_ID, userId);
    }
    this.API.post("api/v1/web/questions/first", {
      user_id: userId,
      store,
    }).then((res) => {
      let currentSteps = [];
      const { old_questions, next_questions, user_answers, uuid } = res.data;
      Object.keys(old_questions || {})?.map((questionId) => {
        const questions = old_questions[questionId];
        currentSteps.push({
          title: old_questions[questionId][0].q_text,
          questions,
        });
      });
      currentSteps = currentSteps
        .sort((a, b) => (a.questions[0].q_id > b.questions[0].q_id ? 1 : -1))
        .map((item, index) => ({
          ...item,
          id: index + 1,
          answerId: +user_answers[index].split(":")[1],
        }));
      Object.keys(next_questions || {})?.map((title) => {
        currentSteps.push({
          id: currentSteps.length + 1,
          title,
          questions: next_questions[title],
        });
      });
      const questionId = Math.floor(
        +currentSteps[currentSteps.length - 1].questions[0].q_id
      );
      let currentActiveProgress = 0;
      switch (questionId) {
        case 1:
          currentActiveProgress = 0;
          break;
        case 2:
          currentActiveProgress = 1;
          break;
        case 3:
        case 4:
        case 5:
        case 7:
        case 9:
          currentActiveProgress = 2;
          break;
        default:
          currentActiveProgress = 3;
          break;
      }
      this.setState({
        steps: steps.map((step) => ({
          ...step,
          ...currentSteps.find((currentStep) => currentStep.id === step.id),
        })),
        uuid,
        activeStep: currentSteps.length + (!next_questions ? 1 : 0),
        activeProgress: currentActiveProgress,
        isConfirmed: !next_questions,
        userId,
      });
    })
    .catch(() => {})
    .finally(() => {
      window.scrollTo(0, 0);
    });
  };

  getSpeechBubbleText = () => {
    const actualStepLength = this.getActualStepLength();
    const lastQuestion = this.state.steps.filter((item) => item.title)[
      this.getActualStepLength() - 1
    ];
    const questionId = Math.floor(+lastQuestion.questions[0].q_id);
    if ([2, 3].includes(questionId)) {
      return `${actualStepLength}問目`;
    }
    if ([4, 5, 7, 9, 10].includes(questionId)) {
      return "あともう少し";
    }
    return "";
  };

  getSpeechBubbleClassName = () => {
    const lastQuestion = this.state.steps.filter((item) => item.title)[
      this.getActualStepLength() - 1
    ];
    const questionId = Math.floor(+lastQuestion.questions[0].q_id);
    switch (questionId) {
      case 2:
      case 3:
        return "next";
      case 4:
      case 5:
      case 7:
      case 9:
      case 10:
        return "nearly";
      case 6:
      case 8:
      case 11:
        return "last";
      default:
        return "";
    }
  };

  getActualStepLength = () => {
    return this.state.steps.filter((item) => item.title).length;
  };

  getWebResult = () => {
    this.setState({ isDisabled: true });
    const { userId, store, uuid } = this.state;
    this.API.post("api/v1/web/results", {
      user_id: userId,
      store,
      uuid,
    })
      .then((res) =>
        this.props.history.push(`/web-survey-result${window.location.search}`, {
          data: res.data || {},
        })
      )
      .catch((err) => {
        this.setState({ isConfirmed: false });
        if (!err) {
          this.alertError();
          return;
        }
      });
  };

  isLoadMore(index) {
    let lastStep = false;
    const lastQuestion = this.state.steps.filter((item) => item.title)[
      this.getActualStepLength() - 1
    ];
    const questionId = Math.floor(+lastQuestion.questions[0].q_id);
    if ([6, 8, 11].includes(questionId)) {
      lastStep = true;
    }
    return (
      index &&
      index === this.getActualStepLength() - 1 &&
      !lastStep &&
      !this.state.isConfirmed
    );
  }

  getNextQuestion = (stepId, question) => {
    const { uuid, userId, store, steps, lastStepId } = this.state;
    const activeStepIndex = steps.findIndex((step) => step.id === stepId);
    steps[activeStepIndex].disabled = true;
    this.API.post("api/v1/web/guest-answers/answer", {
      a_id: `${question.a_id}`,
      q_id: question.q_id,
      uuid,
      user_id: userId,
      store,
    })
      .then((res) => {
        steps[activeStepIndex].answerId = question.a_id;
        for (let index = activeStepIndex + 1; index < 10; index++) {
          steps[index] = {
            ...steps[index],
            title: "",
            questions: [],
            answerId: null,
          };
        }
        this.setState({
          steps,
          activeStep: stepId + 1,
          isConfirmed: stepId === lastStepId,
        });
        if (res.data.flow_done) {
          this.setState({
            isConfirmed: true,
            isDisabled: false,
            lastStepId: stepId,
          });
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth",
          });
        } else {
          let currentActiveProgress = 0;
          const nextQuestion = res.data;
          const nextStepIndex = steps.findIndex(
            (step) => step.id === stepId + 1
          );
          Object.keys(nextQuestion).map((title) => {
            steps[nextStepIndex] = {
              ...steps[nextStepIndex],
              title,
              questions: nextQuestion[title],
              answerId: null,
            };
            const questionId = Math.floor(+nextQuestion[title][0].q_id);
            switch (questionId) {
              case 1:
                currentActiveProgress = 0;
                break;
              case 2:
                currentActiveProgress = 1;
                break;
              case 3:
              case 4:
              case 5:
              case 7:
              case 9:
                currentActiveProgress = 2;
                break;
              default:
                currentActiveProgress = 3;
                break;
            }
          });
          for (let index = nextStepIndex + 1; index < 10; index++) {
            steps[index] = {
              ...steps[index],
              title: "",
              questions: [],
              answerId: null,
            };
          }
          this.setState({
            steps,
            isConfirmed: false,
            activeProgress: currentActiveProgress,
            lastStepId: null,
          });
          const nextQuestionEl = document.querySelector(
            ".question-item.active"
          );
          const offsetTop =
            nextQuestionEl.offsetTop -
            window.innerHeight / 2 +
            (nextQuestionEl.children[0].clientHeight + 48) / 2;
          window.scrollTo({
            top: offsetTop,
            behavior: "smooth",
          });
        }
      })
      .catch((err) => {
        if (!err) {
          this.alertError();
          return;
        }
        steps[activeStepIndex].answerId = null;
        this.setState({
          steps,
          activeStep: stepId,
        });
      })
      .finally(() => {
        steps[activeStepIndex].disabled = false;
      });
  };

  alertError = async () => {
    await messageService.show(
      `${NO_INTERNET_MESSAGE.content}${NO_INTERNET_MESSAGE.title}`
    );
  };

  render() {
    const { steps, activeStep, activeProgress, isConfirmed, isDisabled } =
      this.state;

    return (
      <div className="survey-page web">
        {!!this.getActualStepLength() && (
          <>
            <header id="progress" className="progress">
              <div className="progress-status">
                {isConfirmed ? (
                  <span className="count finished">回答完了</span>
                ) : (
                  <>
                    <span className="count">{activeStep}</span> / 約
                    <span className="blue font-weight-bold">10</span>問中
                  </>
                )}
              </div>
              <div className="progress-bar">
                <img
                  src={`${IMG_URL}images/progress-bar.svg`}
                  alt="progress bar image"
                />
                <div
                  className={`progress-line ${
                    isConfirmed ? "step-done" : `step-${activeProgress}`
                  }`}
                ></div>
              </div>
            </header>
            <section className="page-content">
              <p className="page-survey-text">【ライフリー大人用紙おむつ診断】</p>
              <h1 className="page-title">
                一問につき答えを一つ選択してください。質問数は約10問です。
              </h1>
              <ul className="question-list">
                {steps.map((step, index) => {
                  if (!step.title) return;
                  return (
                    <li
                      key={step.id}
                      className={`question-item ${
                        step.id === activeStep ? "active" : ""
                      }`}
                    >
                      <div className="question-wrapper">
                        <span
                          className={`speech-bubble ${this.getSpeechBubbleClassName()}`}
                        >
                          {this.getSpeechBubbleText()}
                        </span>
                        <h3 className="question-title">{step.title}</h3>
                        <div
                          className={`btn-group ${
                            step.questions.some(
                              (question) => question?.a_text.length > 5
                            )
                              ? "column"
                              : step.questions.length === 4 &&
                                step.questions.every(
                                  (question) => question?.a_text.length <= 4
                                )
                              ? "wrap"
                              : ""
                          }`}
                        >
                          {step.questions.map((question) => (
                            <button
                              key={question.a_id}
                              className={`btn ${
                                step.answerId === question.a_id
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() => {
                                if (
                                  step.answerId !== question.a_id &&
                                  !steps.filter(step => step.disabled).length
                                ) {
                                  this.getNextQuestion(step.id, question);
                                }
                              }}
                            >
                              <span className="text-btn">
                                {question.a_text}
                              </span>
                            </button>
                          ))}
                        </div>
                      </div>
                      {!!this.isLoadMore(index) && (
                        <div>
                          <img
                            className="w-100"
                            src={`${IMG_URL}images/load-more-web.svg`}
                          />
                        </div>
                      )}
                      {this.getActualStepLength() === 1 && (
                        <div className="load-first">
                          <img src={`${IMG_URL}images/load-first.svg`} />
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
              <button
                className={`btn btn-submit ${
                  isConfirmed ? "confirmed" : "d-none-imp"
                }`}
                onClick={() => this.getWebResult()}
                disabled={!isConfirmed || isDisabled}
              >
                <img
                  src={`${IMG_URL}images/ic-submit.svg`}
                  alt="progress bar image"
                />
                診断結果を見る
              </button>
            </section>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(WebSurvey);
