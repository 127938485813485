import { Howl, Howler } from 'howler';
import React, { Component } from 'react';
import { IMG_URL } from '../../../../config/config';
import Button from '../../modules/Button';
import store from '../../../service/store';

export class SoundSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sound: null,
      volume: store['VOLUME_AUDIO'] || 50,
    }
  }

  componentDidMount() {
    document.querySelector('#app').classList.add('app-except-background');
    window.scrollTo(0, 0);
    this.state.sound = new Howl({
      src: `${IMG_URL}audio/during_measurement.wav`,
      onplayerror: function () {
        this.state.sound.once('unlock', function () {
          this.state.sound.play();
        });
      }
    });
    this.state.sound.play();
    Howler.volume(this.state.volume / 100);
    this.preloadAudio();
  }

  componentWillUnmount() {
    document.querySelector('#app').classList.remove('app-except-background');
    this.state.sound.stop();
  }

  handleSubmit = () => {
    this.props.history.push('camera')
  }

  changeValume = (event) => {
    let value = event.target.value;
    store['VOLUME_AUDIO'] = value;
    Howler.volume(value / 100);
    this.setState({
      volume: value
    })
  }

  preloadAudio = () => {
    new Howl({
      src: [`${IMG_URL}audio/books_and.wav`]
    }),
    new Howl({
      src: [`${IMG_URL}audio/smartphone.wav`]
    })
    return;
  }

  render() {
    const { volume } = this.state;
    return (
      <div className="sound-settings">
        <section>
          <div className="is-front-sound-settings w-100">
            <p className="front-shooting-title title-sound-setting">計測中は音声で<br />ご案内します<br />スマホ本体のボタンで<br /><img className="uc-arrow" src={`${IMG_URL}images/arrow-white.png`} alt="arrow" />の位置まで<br />音量を上げましょう</p>
            <div className="preparation-sound">
              <img src={`${IMG_URL}images/sound.png`} alt="sound" />
            </div>

            <div className="volume-container">
              <img className="uc-arrow-volume" src={`${IMG_URL}images/arrow-white.png`} alt="arrow" />
              <div className="volume-track">
                <div className={`volume-track-bar ${volume > 50 ? "bg-turquoise" : ""} `} style={{ width: volume + "%" }}></div>
              </div>
              <input className="volume-control" type="range" min="0" max="100" value={volume} onChange={this.changeValume} />
            </div>
            <Button className="btn btn-submit-audio" content="次へ" onClick={this.handleSubmit} />
          </div>
        </section>
      </div>
    );
  }
}

export default SoundSettings
