import {
  Introduction,
  BodySizeInput,
  BodySizeMeasureMethod,
  WaistMeasurements,
  Uploading,
  Result,
  PreparationCloth,
  PreparationShoot,
  ReactWebcam,
  PageNotFound,
  SoundSettings,
  Survey,
  WebSurvey,
  WebSurveyResult
} from './components/container'

export const routes = [
  {
    exact: true,
    path: '/',
    component: Introduction
  },
  {
    exact: true,
    path: '/introduction',
    component: Introduction
  },
  {
    exact: true,
    path: '/survey',
    component: Survey,
    header: false
  },
  {
    exact: true,
    path: '/web-survey',
    component: WebSurvey
  },
  {
    exact: true,
    path: '/web-survey-result',
    component: WebSurveyResult
  },
  {
    exact: true,
    path: '/uploading-bodygram',
    component: Uploading,
    header: { type: 'camera', isTransparent: false }
  },
  {
    exact: true,
    path: '/result',
    component: Result
  },
  {
    exact: true,
    path: '/body-size-input',
    component: BodySizeInput
  },
  {
    exact: true,
    path: '/body-size-get-method',
    component: BodySizeMeasureMethod
  },
  {
    exact: true,
    path: '/waist-measurements',
    component: WaistMeasurements
  },
  {
    exact: true,
    path: '/preparation-cloth',
    component: PreparationCloth
  },
  {
    exact: true,
    path: '/preparation-shoot',
    component: PreparationShoot
  },
  {
    exact: true,
    path: '/camera',
    component: ReactWebcam,
    header: { type: 'camera', isTransparent: true }
  },
  {
    exact: true,
    path: '/sound-settings',
    component: SoundSettings,
    header: { type: 'camera', isTransparent: true, isTransparentPortrait: true }
  },
  {
    exact: true,
    path: '/404',
    component: PageNotFound
  }
]
