import React, { Component } from 'react'

export class Button extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isDisabled: !!this.props.isDisabled,
      deplayClick: null
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      isDisabled: !!newProps.isDisabled,
      deplayClick: newProps.deplayClick || null
    })
  }

  onClick = () => {
    this.setState({ isDisabled: !this.props.isFreeClick }, () => {
      this.props.onClick && this.props.onClick()
    })
    if (this.state.deplayClick) {
      setTimeout(() => {
        this.setState({
          isDisabled: false
        })
      }, this.state.deplayClick);
    }
  }

  render() {
    const { isDisabled } = this.state

    return (
      <button disabled={isDisabled}
              className={`${this.props.className} ${isDisabled ? 'disabled' : ''}`}
              onClick={this.onClick} dangerouslySetInnerHTML={{ __html: `${this.props.content}` }}>
      </button>
    )
  }
}

export default Button
