import React, { Component } from "react";
import Webcam from "react-webcam";
import UAParser from "ua-parser-js";
import store from "../../../service/store";
import { BODY_SIZE_METHOD, IMG_URL, IS_WEB } from "../../../../config/config";
import { Howl, Howler } from "howler";

const FACING_MODE_USER = "user"; // front camera
const FACING_MODE_ENVIRONMENT = "environment"; // rear camera
const TIME_OUT_GUIDE = 3000; // 3s
const TIME_OUT_IMG_SUCCESS = 3000; // 3s
const TIME_OUT_SHOOTING_DONE = 6000; //6s
const Camera = {
  data: {
    clientWidth: 0,
    clientHeight: 0,
    sensorDirection: 1,
    vIndicator: undefined,
    hIndicator: undefined,
    cIndicator: undefined,
    yawAngle: 0,
    pitchAngle: 0,
    previousGravity: {
      x: 0,
      y: 0,
      z: 0,
    },
    canCapture: false,
    isSelfie: false,
  },
};

export class ReactWebcam extends Component {
  mounted = false;
  intervalId = null;
  methodLocal = localStorage.getItem(BODY_SIZE_METHOD);

  constructor(props) {
    super(props);
    this.webcamRef = React.createRef();
    this.imageSrc = null;
    this.state = {
      orientation: "Portrait",
      countdownNumber: 10,
      status: "SELFIE_FRONT_SHOOTING",
      facingMode:
        this.methodLocal === "SelfTakePhoto"
          ? FACING_MODE_USER
          : FACING_MODE_ENVIRONMENT,
      isFlicked: false,
      isShooting: false,
      isShowSideDone: false,
      isFrontShootingDone: false,
      isFrontShootingGuidance: false,
      isSelfieSideShootingDone: false,
      isShowHintMark: true,
      canCapture: false,
      autoPlayAudio: true,
      listAudio: {},
      method: this.methodLocal || "SelfTakePhoto",
      videoConstraints: {
        height: 1080,
        width: 1920,
      },
    };
  }

  componentDidMount() {
    const version = this.iOSversion();
    if (version && version[0] >= 14 && version[0] <= 15) {
      this.setState({
        videoConstraints: {
          ...this.state.videoConstraints,
          aspectRatio: 16 / 9,
        },
      });
    }
    const listFileAudio = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "shoot_button",
      "books_and",
      "frontal_shooting",
      "continue",
      "side_shooting",
      "smartphone",
      "shooting_voice",
    ];
    this.state.listAudio = Object.assign(
      ...listFileAudio.map((k) => ({
        [k]: new Howl({
          src: [`${IMG_URL}audio/${k}.wav`],
        }),
      }))
    );
    Howler.volume((store["VOLUME_AUDIO"] || 50) / 100);
    this.mounted = true;
    window.scrollTo(0, 0);
    this.checkOrientation();
    this.handleSensorCamera();
    window.addEventListener("resize", () => {
      this.intervalId && clearInterval(this.intervalId);
      this.checkOrientation();
      setTimeout(() => {
        this.handleSensorCamera();
      }, 300);
    });
    document.querySelector(".header-page") &&
      document
        .querySelector(".header-page")
        .classList.add(
          window.innerWidth > window.innerHeight ? "is-relative" : "is-fixed"
        );
  }

  componentWillUnmount() {
    this.stopAllAudio();
    this.state.listAudio = [];
    this.mounted = false;
    this.intervalId && clearInterval(this.intervalId);
    document.querySelector(".header-page") &&
      document.querySelector(".header-page").classList.remove("is-fixed");
  }

  playAudio(audio, checkMakeSound = false) {
    if (this.state.listAudio[audio] && !this.state.listAudio[audio].playing()) {
      if (!checkMakeSound || !this.state.listAudio[audio].makeSound) {
        return this.state.listAudio[audio].play();
      }
    }
  }

  stopAudio(audio) {
    if (this.state.listAudio[audio]) {
      return this.state.listAudio[audio].stop();
    }
  }

  stopAllAudio() {
    for (let audio in this.state.listAudio) {
      this.stopAudio(audio);
    }
  }

  setMakeSound(audio, value = true) {
    if (this.state.listAudio[audio]) {
      return (this.state.listAudio[audio].makeSound = value);
    }
  }

  setVolumeAllAudio(volume) {
    for (let audio in this.state.listAudio) {
      if (
        this.state.listAudio[audio] &&
        this.state.listAudio[audio].playing()
      ) {
        return this.state.listAudio[audio].volume(volume);
      }
    }
  }

  checkOrientation = () => {
    const orientation =
      window.innerWidth > window.innerHeight ? "Landscape" : "Portrait";
    this.setState({
      orientation,
    });
  };

  handleSensorCamera = () => {
    // Use camera area size for indicators' movement
    Camera.data.clientWidth =
      document.getElementById("camera-container").clientWidth;
    Camera.data.clientHeight =
      document.getElementById("camera-container").clientHeight;
    // OS
    Camera.data.sensorDirection = ["iOS", "Mac OS"].includes(
      new UAParser().getOS().name
    )
      ? 1
      : -1;
    // Indicators which shows device's tilt
    Camera.data.vIndicator = document.getElementById("v-indicator");
    Camera.data.hIndicator = document.getElementById("h-indicator");
    Camera.data.cIndicator = document.getElementById("c-indicator");
    // Centering indicators
    Camera.data.vIndicator.style.left = `${Camera.data.clientWidth / 2}px`;
    Camera.data.hIndicator.style.top = `${Camera.data.clientHeight / 2}px`;
    Camera.data.cIndicator.style.top = `${Camera.data.clientHeight / 2}px`;
    Camera.data.cIndicator.style.left = `${Camera.data.clientWidth / 2}px`;
    window.addEventListener(
      "devicemotion",
      (e) => {
        let t = e.accelerationIncludingGravity;
        let a = t.x;
        let n = t.y;
        var r = t.z;
        let i = e.acceleration || { x: 0, y: 0, z: 0 };
        let o = { x: a - i.x, y: n - i.y, z: r - i.z };
        o.x = 0.95 * Camera.data.previousGravity.x + (1 - 0.95) * o.x;
        o.y = 0.95 * Camera.data.previousGravity.y + (1 - 0.95) * o.y;
        o.z = 0.95 * Camera.data.previousGravity.z + (1 - 0.95) * o.z;
        Camera.data.pitchAngle = (o.z / 9.8) * 90 * Camera.data.sensorDirection;
        let l = Math.atan2(o.y, o.x) * Camera.data.sensorDirection;
        Camera.data.previousGravity = o;
        l < 0 && l > -1 * Math.PI
          ? (l += Math.PI / 2)
          : l >= Math.PI / 2 && l < Math.PI
          ? (l -= (3 * Math.PI) / 2)
          : (l += Math.PI / 2);
        Camera.data.yawAngle = (180 * l) / Math.PI;
      },
      true
    );
    this.intervalId = setInterval(() => {
      let yaw = Camera.data.yawAngle / 180; // -1 < yaw < 1
      let pitch = Camera.data.pitchAngle / 90; // -1 <= pitch <= 1

      yaw = Math.max(Math.min(1, yaw), -1);
      pitch = Math.max(Math.min(1, pitch), -1);

      // let vLeft = Camera.data.clientWidth * (Math.sin(yaw * Math.PI / 2) + 0.5)
      let hTop = (Camera.data.clientHeight * (pitch + 1)) / 2;
      let hTopC =
        Camera.data.clientHeight * ((Math.sin((pitch / 2) * Math.PI) + 1) / 2);
      // Camera.data.vIndicator.style.left = `${vLeft}px`
      Camera.data.hIndicator.style.transform = `rotate(${Camera.data.yawAngle}deg) scaleX(100)`;
      Camera.data.hIndicator.style.top = `${hTop}px`;
      // Camera.data.cIndicator.style.left = `${vLeft}px`
      Camera.data.cIndicator.style.top = `${hTopC}px`;
      const isYawCenter =
        Math.abs(Camera.data.yawAngle) < (Camera.data.isSelfie ? 10 : 3);
      const isPitchCenter =
        Math.abs(Camera.data.pitchAngle) < (Camera.data.isSelfie ? 10 : 3);
      Camera.data.canCapture = isYawCenter && isPitchCenter;
      Camera.data.vIndicator.style.backgroundColor = isYawCenter
        ? "white"
        : "red";
      Camera.data.hIndicator.style.backgroundColor = isPitchCenter
        ? "white"
        : "red";
      Camera.data.cIndicator.style.backgroundColor = "white";
      Camera.data.canCapture
        ? Camera.data.cIndicator.classList.add("is-valid")
        : Camera.data.cIndicator.classList.remove("is-valid");
      this.mounted &&
        this.setState({
          canCapture: Camera.data.canCapture,
        });
    }, 5);
  };

  onShooting = async (method = "SelfTakePhoto") => {
    this.setState({ isShooting: true });
    // === Count down ===
    if (method === "SelfTakePhoto") {
      await this.countdown(10);
      this.imageSrc = this.webcamRef.current.getScreenshot({
        width: 1080,
        height: 1920,
      });
      if (this.state.status === "SELFIE_FRONT_SHOOTING") {
        store["SELFIE_FRONT_IMG"] = this.imageSrc;
        this.isShowFrontDone = true;
        setTimeout(() => {
          this.isShowFrontDone = false;
          this.setState({
            status: "SELFIE_SIDE_SHOOTING",
          });
        }, TIME_OUT_GUIDE);
      } else {
        store["SELFIE_SIDE_IMG"] = this.imageSrc;
        this.isShowSideDone = true;
        setTimeout(() => {
          this.isShowSideDone = false;
        }, TIME_OUT_GUIDE);
      }
      this.setState({
        isFlicked: true,
        isShooting: false,
      });
      setTimeout(() => {
        this.setState({ isFlicked: false });
        if (this.state.status === "SELFIE_FRONT_SHOOTING") {
          this.setState({ isFrontShootingDone: true }, () => {
            setTimeout(() => {
              this.setState({ isFrontShootingDone: false }, () => {
                this.setState({ isFrontShootingGuidance: true }, () => {
                  setTimeout(async () => {
                    this.setState({
                      isFrontShootingGuidance: false,
                      isShooting: true,
                    });
                    // Handle auto capture selfie side
                    await this.countdown(5);
                    this.imageSrc = this.webcamRef.current.getScreenshot();
                    store["SELFIE_SIDE_IMG"] = this.imageSrc;
                    this.isShowSideDone = true;
                    setTimeout(() => {
                      this.isShowSideDone = false;
                    }, TIME_OUT_GUIDE);
                    this.setState({
                      isFlicked: true,
                      isShooting: false,
                    });
                    this.setState({ isSelfieSideShootingDone: true }, () => {
                      setTimeout(() => {
                        this.setState({ isSelfieSideShootingDone: false });
                        this.props.history.push("/uploading-bodygram");
                      }, TIME_OUT_IMG_SUCCESS);
                    });
                  }, TIME_OUT_SHOOTING_DONE);
                });
              });
            }, TIME_OUT_IMG_SUCCESS);
          });
        }
      }, 300);
    } else {
      // TakePhotoByOther
      this.playAudio("shooting_voice");
      this.imageSrc = this.webcamRef.current.getScreenshot();
      if (this.state.status === "SELFIE_FRONT_SHOOTING") {
        store["SELFIE_FRONT_IMG"] = this.imageSrc;
        this.isShowFrontDone = true;
        setTimeout(() => {
          this.isShowFrontDone = false;
          this.setState({
            status: "SELFIE_SIDE_SHOOTING",
          });
        }, TIME_OUT_GUIDE);
      } else {
        store["SELFIE_SIDE_IMG"] = this.imageSrc;
        this.isShowSideDone = true;
        setTimeout(() => {
          this.isShowSideDone = false;
        }, TIME_OUT_GUIDE);
      }
      this.setState({
        isFlicked: true,
        isShooting: false,
      });
      setTimeout(() => {
        this.setState({ isFlicked: false });
        if (this.state.status === "SELFIE_FRONT_SHOOTING") {
          this.setState({ autoPlayAudio: false });
          this.setState({ isFrontShootingDone: true }, () => {
            setTimeout(() => {
              this.setState({ isFrontShootingDone: false }, () => {
                this.setState({ isFrontShootingGuidance: true }, () => {
                  setTimeout(() => {
                    this.setState({ isFrontShootingGuidance: false });
                  }, TIME_OUT_SHOOTING_DONE);
                });
              });
              this.setState({ autoPlayAudio: true });
            }, TIME_OUT_IMG_SUCCESS);
          });
        } else {
          this.setState({ isSelfieSideShootingDone: true }, () => {
            setTimeout(() => {
              this.setState({ isSelfieSideShootingDone: false });
              this.props.history.push("/uploading-bodygram");
            }, TIME_OUT_IMG_SUCCESS);
          });
        }
      }, 300);
    }
  };

  countdown = (countdownNumberInput = 10) => {
    let countdownNumber = countdownNumberInput;
    this.stopAllAudio();
    this.setMakeSound("books_and");
    this.setMakeSound("shoot_button");
    this.playAudio(countdownNumberInput);
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        this.playAudio(countdownNumber - 1);
        this.setState({
          countdownNumber: --countdownNumber,
        });
        if (countdownNumber === 0) {
          this.playAudio("shooting_voice");
        }
        if (countdownNumber === -1) {
          this.setState({
            countdownNumber: 5,
          });
          clearInterval(interval);
          resolve();
        }
      }, 1000);
    });
  };

  hideHintMark = () => {
    this.setState({
      isShowHintMark: false,
    });
  };

  iOSversion = () => {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    }
    return;
  };

  render() {
    const {
      method,
      status,
      facingMode,
      isFlicked,
      isShooting,
      isFrontShootingDone,
      isFrontShootingGuidance,
      isSelfieSideShootingDone,
      orientation,
      canCapture,
      countdownNumber,
      videoConstraints,
      isShowHintMark,
      autoPlayAudio,
    } = this.state;
    const width = window.innerWidth;
    const height = window.innerHeight;
    const dNone =
      !isShooting && !isFrontShootingDone && !isSelfieSideShootingDone
        ? ""
        : "d-none";
    const typeMessage = isShooting
      ? "COUNT_DOWNING"
      : canCapture
      ? "CAN_CAPTURE"
      : "CAN_NOT_CAPTURE";
    const isSelfTake = method === "SelfTakePhoto";
    const audioNameDontMatch = isSelfTake ? "books_and" : "smartphone";
    if (
      autoPlayAudio &&
      !isFrontShootingGuidance &&
      !isShooting &&
      !isFrontShootingDone &&
      !isSelfieSideShootingDone
    ) {
      if (canCapture) {
        this.stopAudio(audioNameDontMatch);
        this.setMakeSound(audioNameDontMatch, false);
        this.playAudio("shoot_button", true);
        this.setMakeSound("shoot_button");
      }
      if (!canCapture) {
        this.stopAudio("shoot_button");
        this.setMakeSound("shoot_button", false);
        this.playAudio(audioNameDontMatch, true);
        this.setMakeSound(audioNameDontMatch);
      }
    }
    if (isFrontShootingDone) {
      this.stopAudio(audioNameDontMatch);
      this.stopAudio("shoot_button");
      this.playAudio("frontal_shooting", true);
      this.setMakeSound("frontal_shooting");
      this.setMakeSound("shoot_button", false);
    }
    if (isFrontShootingGuidance) {
      this.stopAudio(audioNameDontMatch);
      this.stopAudio("frontal_shooting");
      this.stopAudio("shoot_button");
      this.playAudio("continue", true);
      this.setMakeSound("continue");
      setTimeout(() => {
        this.stopAudio("continue", true);
      }, TIME_OUT_SHOOTING_DONE);
    }
    if (isSelfieSideShootingDone) {
      this.stopAudio(audioNameDontMatch);
      this.stopAudio("frontal_shooting");
      this.stopAudio("shoot_button");
      this.playAudio("side_shooting", true);
      this.setMakeSound("side_shooting");
    }
    const isWeb = localStorage.getItem(IS_WEB);

    return (
      <>
        {
          <div
            id="camera-container"
            className={`react-webcam ${isFlicked ? "is-flicked" : ""} ${
              orientation === "Landscape" ? "d-none" : ""
            }`}
          >
            <div className={dNone}>
              <div id="v-indicator" className="indicator"></div>
              <div id="h-indicator" className="indicator"></div>
              <div id="c-indicator"></div>
            </div>
            <Webcam
              audio={false}
              screenshotFormat="image/jpeg"
              ref={this.webcamRef}
              mirrored={facingMode === FACING_MODE_USER}
              forceScreenshotSourceSize
              width={width}
              height={height}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
              videoConstraints={{
                ...videoConstraints,
                facingMode,
              }}
            />
            {!isFrontShootingGuidance && (
              <>
                {status === "SELFIE_FRONT_SHOOTING" && (
                  <img
                    className="img-body front-body"
                    src={`${IMG_URL}images/front-body.png`}
                    alt="img body"
                  />
                )}
                {status === "SELFIE_SIDE_SHOOTING" && (
                  <img
                    className={`img-body side-body ${
                      !isSelfTake ? "flip-img-overwrite" : ""
                    }`}
                    src={`${IMG_URL}images/side-body.png`}
                    alt="img body"
                  />
                )}
                {!isShooting &&
                  !isFrontShootingDone &&
                  !isSelfieSideShootingDone && (
                    <>
                      <img
                        className="img-body img-center"
                        src={`${IMG_URL}images/${
                          canCapture ? "eclip-done" : "eclip"
                        }.png`}
                        alt="image-eclip"
                      />
                      <div
                        className={`tooltip-inner ${
                          canCapture || status === "SELFIE_SIDE_SHOOTING"
                            ? "d-none"
                            : ""
                        }`}
                      >
                        白い丸がオレンジの枠内に入る様にしましょう♪
                        <div className="tooltip-angle"></div>
                      </div>
                      <div
                        className={`hint-mark-camera ${
                          !isShowHintMark ||
                          canCapture ||
                          status === "SELFIE_SIDE_SHOOTING"
                            ? "d-none"
                            : "d-flex"
                        }`}
                      >
                        <img
                          className="img-close-hint-mark"
                          src={`${IMG_URL}images/close.png`}
                          alt="close-hint-mark"
                          onClick={this.hideHintMark}
                        />
                        <p className="hint-mark-title">ヒント</p>
                        <span
                          className={`hint-mark-degrees ${
                            isSelfTake ? "" : "d-none"
                          }`}
                        >
                          90°
                        </span>
                        <img
                          className="img-hint-mark-pic-selfie"
                          src={`${IMG_URL}images/${
                            isSelfTake
                              ? "hint-mark-pic-selfie"
                              : "hint-mark-pic-other"
                          }.png`}
                          alt="img-hint-mark"
                        />
                        <p className="hint-mark-detail">
                          {isSelfTake
                            ? "スマホが垂直に立つよ"
                            : "スマホをかたむけるこ"}
                          <br />
                          {isSelfTake
                            ? "うに立てかけましょう"
                            : "とで 調整できます"}
                        </p>
                      </div>
                      {canCapture &&
                        (isSelfTake ? (
                          status === "SELFIE_FRONT_SHOOTING" && (
                            <button
                              className="btn-capture"
                              onClick={() => this.onShooting("SelfTakePhoto")}
                            >
                              <img
                                className="ic-capture"
                                src={`${IMG_URL}images/ic_capture.png`}
                                alt="icon capture"
                              />
                              <span>10秒後に撮影</span>
                            </button>
                          )
                        ) : (
                          <button
                            className="btn-capture"
                            onClick={() => this.onShooting("TakePhotoByOther")}
                          >
                            <img
                              className="ic-capture"
                              src={`${IMG_URL}images/ic_capture.png`}
                              alt="icon capture"
                            />
                            <span>撮影</span>
                          </button>
                        ))}
                    </>
                  )}
              </>
            )}
          </div>
        }
        {isSelfTake && isShooting && (
          <div className="is-shooting w-100 is-fixed">{countdownNumber}</div>
        )}
        {isFrontShootingDone && (
          <>
            <div
              className="img-preview"
              style={{
                backgroundImage: `url(${this.imageSrc})`,
              }}
            >
              <img
                className="img-body front-body"
                src={`${IMG_URL}images/front-body.png`}
                alt="img body"
              />
            </div>
            <div className="is-bg-shooting-done w-100 is-fixed"></div>
            <div className="is-front-shooting-done w-100 is-fixed">
              <img
                className="front-shooting-img"
                src={`${IMG_URL}images/ic-done.png`}
                alt="Icon done"
              />
              <p className="front-shooting-title">
                正面撮影が
                <br />
                完了しました
              </p>
            </div>
          </>
        )}
        {isFrontShootingGuidance && (
          <>
            <div
              className="img-preview"
              style={{
                backgroundImage: `url(${this.imageSrc})`,
              }}
            ></div>
            <div className="is-front-shooting-guidance w-100 is-fixed">
              <img
                className={`front-shooting-img ${
                  !isSelfTake ? "flip-img" : ""
                }`}
                src={`${IMG_URL}images/ic-guidance.png`}
                alt="Icon guidance"
              />
              <p className="front-shooting-title">
                左回りに回転し
                <br />
                横を向いてください
              </p>
            </div>
          </>
        )}
        {isSelfieSideShootingDone && (
          <>
            <div
              className="img-preview"
              style={{
                backgroundImage: `url(${this.imageSrc})`,
              }}
            >
              <img
                className={`img-body side-body ${
                  !isSelfTake ? "flip-img-overwrite" : ""
                }`}
                src={`${IMG_URL}images/side-body.png`}
                alt="img body"
              />
            </div>
            <div className="is-bg-selfie-shooting-done w-100 is-fixed"></div>
            <div className="is-selfie-side-shooting-done w-100 is-fixed">
              <img
                className="front-shooting-img"
                src={`${IMG_URL}images/ic-done.png`}
                alt="Icon done"
              />
              <p className="front-shooting-title">
                側面撮影が
                <br />
                完了しました
              </p>
            </div>
          </>
        )}
        {
          <div
            className={`is-landscape ${
              orientation === "Landscape" ? "" : "d-none"
            } ${isWeb ? "is-web" : ""}`}
          >
            {isWeb
              ? `
            この先の手順に進むことができません。以下をご確認下さい。
            ・このサービスは、スマートフォン、タブレットのみでご利用いただけます。
            ・スマートフォンでご利用の場合は、カメラを縦向きにしてください。
            `
              : "カメラは縦向きで利用してください"}
          </div>
        }
      </>
    );
  }
}

export default ReactWebcam;
