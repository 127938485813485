import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import introData from "../../../assets/fakeApi/introData";
import IntroItem from "../../../assets/fakeApi/IntroItem";
import {
  JWT_TOKEN,
  GENDER,
  UUID,
  USER_MEASUREMENT,
  WAIST_MEASUREMENT,
  BODY_SIZE_METHOD,
  IMG_URL,
  IS_WEB,
} from "../../../config/config";
import { searchToObject } from "../../service/utils";
import Button from "../modules/Button";
import APIService from "../../service/api";

export class Introduction extends Component {
  API = new APIService();

  constructor(props) {
    super(props);
    this.state = {
      token: "",
      isConfirmed: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { token, gender, uuid, isWeb } = searchToObject(
      this.props.location.search
    );
    if (token) {
      // If has token then clear local storage to start a new session
      localStorage.removeItem(USER_MEASUREMENT);
      localStorage.removeItem(BODY_SIZE_METHOD);
      localStorage.removeItem(WAIST_MEASUREMENT);
      localStorage.setItem(JWT_TOKEN, token);
      this.checkDataUser(token, isWeb, uuid);
    }
    if (gender) {
      localStorage.setItem(GENDER, gender);
    }
    if (uuid) {
      localStorage.setItem(UUID, uuid);
    }
    if (isWeb) {
      localStorage.setItem(IS_WEB, true);
    } else {
      localStorage.removeItem(IS_WEB);
    }
  }

  checkDataUser = (token, isWeb, uuid) => {
    return this.API.get(
      `api/v1/${isWeb ? "web/guests/check-flow" : "users/checkFlow"}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...(uuid ? { uuid } : {}),
        },
      }
    );
  };

  redirectTo = () => {
    this.props.history.push("/body-size-get-method");
  };

  render() {
    const introItems = introData.map((item) => (
      <IntroItem key={item.id} item={item} />
    ));

    return (
      <>
        {<div className="Introduction">
          <section>
            <h1 className="intro-text-main">最適サイズ診断</h1>
            <p className="intro-text-header font-weight-bold" >
              もれ等のトラブルを防ぐためには、お身体にあったサイズ選びが重要です。
              <br/>
              写真撮影で最適サイズを確認しましょう。
            </p>
          </section>
          <section className="intro-content">
            <div>
              {introItems}
            </div>
          </section>
          <div className="policy-group">
            <label className="label-container">
              <input
                autoComplete="off"
                type="checkbox"
                checked={this.state.isConfirmed}
                onChange={() => this.setState({ isConfirmed: !this.state.isConfirmed })}
              />
              <span className="checkmark"></span>
            </label>
            <a className="policy-link" href="https://www.unicharm.co.jp/ja/privacy-policy.html">プライバシーポリシー</a>に同意する
          </div>
          <Button className="btn" content="診断を始める" onClick={this.redirectTo} isDisabled={!this.state.isConfirmed} />
          <p className="intro-note">※ 撮影は何度でも撮り直しができます。</p>
        </div>}
      </>
    );
  }
}

export default withRouter(Introduction);
