import React, { Component } from 'react'
import { STATE, IMG_URL } from '../../../config/config'

export class Progress extends Component {

  constructor(props) {
    super(props)
    this.state = {
      processing: this.props.process || 1,
    }
  }

  render() {
    const { processing } = this.state;
    STATE
    const url = `${IMG_URL}images/process_${processing}.svg`;
    const alt = `process-${processing}`;
    return (
      <img className="process-img" src={url} alt={alt} />
    )
  }
}

export default Progress
