import axios from "axios";
import React, { Component } from "react";
import APIService from "../../../service/api";
import messageService from "../../../service/messageService";
import store from "../../../service/store";
import Progress from "../../modules/Progress";
import APIBodygramService, {
  REFRESH_TOKEN,
} from "../../../service/APIBodygram";
import { searchToObject } from "../../../service/utils";
import {
  USER_MEASUREMENT,
  IMG_URL,
  BODY_SIZE_METHOD,
} from "../../../../config/config";
import { MESSAGE_API_BODYGRAM } from "../../../../config/message";

export class Uploading extends Component {
  API = new APIService();
  APIBodygram = new APIBodygramService();
  userMeasurement =
    store[USER_MEASUREMENT] ||
    JSON.parse(localStorage.getItem(USER_MEASUREMENT));
  interval = null;
  timeout = null;
  mounted = false;
  axiosCancelSource = axios.CancelToken.source();
  config = { cancelToken: this.axiosCancelSource.token };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      status: "image-waiting",
      waist: null,
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { waist } = searchToObject(this.props.location.search);
    if (waist) {
      this.setState({
        waist,
      });
      store["resultMeasurement"] = {
        waist_girth: waist,
      };
      localStorage.setItem(
        "resultMeasurement",
        JSON.stringify({
          waist_girth: waist,
        })
      );
      this.timeout = setTimeout(() => {
        this.props.history.push(`/result?waist=${waist}`);
      }, 2000);
    } else {
      // Check image is exist?
      if (!store["SELFIE_FRONT_IMG"]) {
        this.props.history.goBack();
      }
      // Always Renew token because confrim email
      const refresh_token = localStorage.getItem(REFRESH_TOKEN);
      if (refresh_token) {
        this.API.renewAccessToken(refresh_token).then((_) => {
          // Always getEstimationID
          this.APIBodygram.getEstimationID(this.userMeasurement).then(
            async (_) => {
              // Handle image before upload to BG
              const frontImg = await this.handleImageBeforeUploadToBodygram(
                "SELFIE_FRONT_IMG"
              );
              const sideImg = await this.handleImageBeforeUploadToBodygram(
                "SELFIE_SIDE_IMG"
              );
              // Request estimated size measurement with image
              Promise.all([
                this.sendCapturedImage(frontImg, "front"),
                this.sendCapturedImage(sideImg, "right"),
              ])
                .then(() => {
                  setTimeout(() => {
                    this.mounted &&
                      this.setState({
                        status: "in-progress",
                      });
                  }, 2000);
                  this.interval = setInterval(() => {
                    // Allaway Renew token because confrim email
                    const refresh_token = localStorage.getItem(REFRESH_TOKEN);
                    if (refresh_token) {
                      this.API.renewAccessToken(refresh_token).then((_) => {
                        this.APIBodygram.getEstimatedSizeMeasurementResult()
                          .then(async (res) => {
                            const measurements = res.measurements[0];
                            if (
                              measurements.status === "completed" &&
                              measurements.estimation
                            ) {
                              this.interval && clearInterval(this.interval);
                              // Check estimation.status === success then pass else then show error
                              if (
                                measurements.estimation.status === "success"
                              ) {
                                if (
                                  measurements.estimation.result?.measurement
                                ) {
                                  store["resultMeasurement"] = measurements.estimation.result.measurement;
                                  localStorage.setItem(
                                    "resultMeasurement",
                                    JSON.stringify(
                                      measurements.estimation.result.measurement
                                    )
                                  );
                                  this.props.history.push("/result");
                                } else {
                                  this.handleErrorOfBodygram(measurements);
                                }
                              } else {
                                this.handleErrorOfBodygram(measurements);
                              }
                            }
                          })
                          .catch((_) => {
                            clearInterval(this.interval);
                            this.APIBodygram.getEstimationID(
                              this.userMeasurement
                            );
                            this.props.history.goBack();
                          });
                      });
                    }
                  }, 3000);
                })
                .catch(async (error) => {
                  if (error?.data) {
                    const message = error.data.message;
                    // Try refresh token when get error 401 for first time
                    if (error.status === 401) {
                      const refresh_token = localStorage.getItem(REFRESH_TOKEN);
                      if (refresh_token) {
                        this.API.renewAccessToken(refresh_token)
                          .then((_) => {
                            this.APIBodygram.getEstimationID(
                              this.userMeasurement
                            );
                            this.props.history.goBack();
                          })
                          .catch((_) => {
                            this.props.history.push("/body-size-input");
                          });
                      } else {
                        await messageService.show(message);
                        this.props.history.push("/body-size-input");
                      }
                    } else {
                      await messageService.show(message);
                      this.APIBodygram.getEstimationID(this.userMeasurement);
                      this.props.history.goBack();
                    }
                  }
                });
            }
          );
        });
      }
    }
  }

  componentWillUnmount() {
    this.interval && clearInterval(this.interval);
    this.timeout && clearTimeout(this.timeout);
    this.axiosCancelSource && this.axiosCancelSource.cancel("Cancel request!");
    messageService.close();
    this.mounted = false;
  }

  handleErrorOfBodygram = async (measurements) => {
    this.APIBodygram.getEstimationID(this.userMeasurement);
    this.mounted &&
      (await messageService.show(
        MESSAGE_API_BODYGRAM[measurements.estimation.code]
          ? MESSAGE_API_BODYGRAM[measurements.estimation.code].message
          : "未知のエラー"
      ));
    this.props.history.goBack();
  };

  handleImageBeforeUploadToBodygram = async (imageType) => {
    let imageConverted = store[imageType];
    const method = localStorage.getItem(BODY_SIZE_METHOD);
    // Flip image in case right and is Seft Take Photo
    if (
      imageConverted &&
      imageType !== "SELFIE_FRONT_IMG" &&
      method === "SelfTakePhoto"
    ) {
      imageConverted = await this.resetOrientation(imageConverted, 2);
    }
    return imageConverted;
  };

  resetOrientation = (srcBase64, srcOrientation) => {
    return new Promise((resolve) => {
      var img = new Image();

      img.onload = function () {
        var width = img.width,
          height = img.height,
          canvas = document.createElement("canvas"),
          ctx = canvas.getContext("2d");

        // set proper canvas dimensions before transform & export
        if (4 < srcOrientation && srcOrientation < 9) {
          canvas.width = height;
          canvas.height = width;
        } else {
          canvas.width = width;
          canvas.height = height;
        }

        // transform context before drawing image
        switch (srcOrientation) {
          case 2:
            ctx.transform(-1, 0, 0, 1, width, 0);
            break;
          case 3:
            ctx.transform(-1, 0, 0, -1, width, height);
            break;
          case 4:
            ctx.transform(1, 0, 0, -1, 0, height);
            break;
          case 5:
            ctx.transform(0, 1, 1, 0, 0, 0);
            break;
          case 6:
            ctx.transform(0, 1, -1, 0, height, 0);
            break;
          case 7:
            ctx.transform(0, -1, -1, 0, height, width);
            break;
          case 8:
            ctx.transform(0, -1, 1, 0, 0, width);
            break;
          default:
            break;
        }

        // draw image
        ctx.drawImage(img, 0, 0);

        // export base64
        resolve(canvas.toDataURL("image/jpeg"));
      };

      img.src = srcBase64;
    });
  };

  sendCapturedImage = async (imageBase64, pattern) => {
    return this.APIBodygram.sendImageByEstimationID(
      {
        image: new File([this.dataURLtoBlob(imageBase64)], "capture"),
        pattern,
      },
      this.config,
      { customError: true }
    );
  };

  dataURLtoBlob = (dataurl) => {
    if (!dataurl) return null;
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  render() {
    const { isLoading, status, waist } = this.state;
    const { height, weight, age, gender } = this.userMeasurement || {};

    return (
      <>
        <div className="uploading-page">
          <section className="uplpading-header">
            <Progress process={3} />
            <div
              className={`uploading-area mt-4 d-flex is-relative ${
                waist ? "bg-blue" : ""
              }`}
            >
              {!waist && (
                <>
                  <div className="img-left w-50">
                    <img
                      className="w-100 object-fit-cover"
                      src={store["SELFIE_FRONT_IMG"]}
                    />
                  </div>
                  <div className="img-right w-50">
                    <img
                      className="w-100 object-fit-cover"
                      src={store["SELFIE_SIDE_IMG"]}
                    />
                  </div>
                </>
              )}
              {status === "image-waiting" && (
                <div className="upload-wrapper">
                  <div className="is-relative">
                    <div className="lds-ring blue">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <img
                      className="icon-loading"
                      src={`${IMG_URL}images/ic-upload.png`}
                      alt="icon-upload"
                    />
                  </div>
                  <h2 className="image-desc">アップロード中</h2>
                </div>
              )}
              {status === "in-progress" && (
                <div className="upload-wrapper">
                  <div className="is-relative">
                    <div className="lds-ring orange">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <img
                      className="icon-loading"
                      src={`${IMG_URL}images/ic-handle-img.png`}
                      alt="icon-handle-image"
                    />
                  </div>
                  <h2 className="image-desc image-desc-progress">分析中</h2>
                </div>
              )}
            </div>
            <div className="d-flex mt-2 justify-content-around">
              <p>前面</p>
              <p>側面</p>
            </div>
          </section>
          <section className="wrap-box d-flex justify-content-evenly mt-4">
            <div className="col-left">
              <p className="gender-info">
                <span>性別</span>
                <span className="body-value font-weight-bold px-3">
                  {gender === "male"
                    ? "男性"
                    : gender === "female"
                    ? "女性"
                    : "-"}
                </span>
              </p>
              <p className="age-info">
                <span>年齢</span>
                <span className="body-value font-weight-bold px-3">{`${
                  age || "-"
                }才`}</span>
              </p>
            </div>
            <div className="col-right">
              <p className="height-info">
                <span>身長</span>
                <span className="body-value font-weight-bold px-3">{`${
                  height || "-"
                } cm`}</span>
              </p>
              <p className="weight-info">
                <span>体重</span>
                <span className="body-value font-weight-bold px-3">{`${
                  weight || "-"
                } kg`}</span>
              </p>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Uploading;
