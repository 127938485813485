import React, { Component } from "react";
import ReactDOM from "react-dom";
import { routes } from "./routers";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Header from "./components/layout/Header";
import APIService from "./service/api";
import { REFRESH_TOKEN } from "./service/APIBodygram";
import { GA_TRACKING_CODE } from "../config/config";
import { SURVEY_ROUTE, WEB_SURVEY_ROUTES } from "../constants/global";

export default class App extends Component {
  API = new APIService();
  interval = null;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    // Renew access token each reload page
    this.renewAccessToken();
    // Renew access token each 3500s
    this.interval = setInterval(() => {
      this.renewAccessToken();
    }, 3500 * 1000);
  }

  renewAccessToken = () => {
    const refresh_token = localStorage.getItem(REFRESH_TOKEN);
    refresh_token && this.API.renewAccessToken(refresh_token);
  };

  componentWillUnmount() {
    this.interval && clearInterval(this.interval);
  }

  render() {
    const { isLoading } = this.state;

    return (
      <>
        <Router>
          {![SURVEY_ROUTE.survey].includes(window.location.pathname) && <Header />}
          <Switch>
            {!isLoading &&
              routes.map((router, i) => {
                const Component = router.component;
                return (
                  <Route
                    key={i}
                    exact={router.exact}
                    path={router.path}
                    render={(props) => {
                      if (router.path === "/") {
                        return <Redirect to={WEB_SURVEY_ROUTES.webSurvey} />;
                      }
                      if (router.path === "/camera") {
                        return <Component {...props} />;
                      }
                      return (
                        <div className="pd-app">
                          <Component {...props} />
                        </div>
                      );
                    }}
                  />
                );
              })}
          </Switch>
        </Router>
      </>
    );
  }
}

if (document.getElementById("app")) {
  ReactDOM.render(<App />, document.getElementById("app"));
}
