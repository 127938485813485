import React from 'react';
import { IMG_URL } from '../../config/config';

function IntroItem(props) {

  return (
    <div className="intro">
      <div className="intro-item">
        <img className="item-image w-100" src={`${IMG_URL}images/${props.item.img}`} />
        <p className="item-content">{props.item.text}</p>
      </div>
    </div>
  )
}
export default IntroItem;
