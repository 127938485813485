const searchToObject = (search) => {
  return search
    .slice(1)
    .split('&')
    .map(p => p.split('='))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
}

const cloneDeep = (data) => {
  return JSON.parse(JSON.stringify(data));
}

export {
  searchToObject,
  cloneDeep,
}
