import axios from "axios";
import messageService from "./messageService";
import { BOT_LINK, IS_WEB, JWT_TOKEN } from "../../config/config";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "./APIBodygram";
import { SURVEY_ROUTES } from "../../constants/global";

const AUTH_TOKEN_LIFF = "AUTH_TOKEN_LIFF";
const AUTH_TOKEN = "AUTH_TOKEN";
const CURRENT_USER = "CURRENT_USER";
const REFRESH_TOKEN_API_URL = (isWeb) =>
  `/api/v1/${isWeb ? "web/guests" : "users"}/body-gram/refresh-token`;
const API = axios.create({
  baseURL: process.env.MIX_END_POINT,
  headers: {
    "Content-Type": "application/json",
  },
});

export default class APIService {
  get(uri, config = null, optional = null) {
    const url = Array.isArray(uri) ? uri.join("/") : uri;
    this.setAuthToken();
    return new Promise(async (resolve, reject) => {
      try {
        const res = await API.get(url, config);
        resolve(res.data);
      } catch (error) {
        reject(error.response);
        this.handleError(error.response, optional);
      }
    });
  }

  getWithoutEndPoint(uri, config = null) {
    const url = Array.isArray(uri) ? uri.join("/") : uri;
    this.setAuthToken();
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(url, config);
        resolve(res.data);
      } catch (error) {
        reject(error.response);
        this.handleError(error.response);
      }
    });
  }

  postWithoutEndPoint(uri, params, config = null, optional = {}) {
    const url = Array.isArray(uri) ? uri.join("/") : uri;
    this.setAuthToken();
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(url, params, config);
        resolve(res.data);
      } catch (error) {
        reject(error.response);
        if (!optional.customError) {
          this.handleError(error.response);
        }
      }
    });
  }

  multiple(requests, config = null) {
    this.setAuthToken();
    return Promise.all(
      requests.map((req) => {
        const url = Array.isArray(req.uri) ? req.uri.join("/") : req.uri;
        return new Promise(async (resolve, reject) => {
          try {
            const res = await API.get(url, config);
            resolve(res.data);
          } catch (error) {
            reject(error.response);
            this.handleError(error.response);
          }
        });
      })
    );
  }

  post(uri, params, config = null, optional = {}) {
    const url = Array.isArray(uri) ? uri.join("/") : uri;
    this.setAuthToken();
    return new Promise(async (resolve, reject) => {
      try {
        const res = await API.post(url, params, config);
        resolve(res.data);
      } catch (error) {
        reject(error.response);
        if (!optional.customError) {
          this.handleError(error.response);
        }
      }
    });
  }

  put(uri, params) {
    const url = Array.isArray(uri) ? uri.join("/") : uri;
    this.setAuthToken();
    return new Promise(async (resolve, reject) => {
      try {
        const res = await API.put(url, params);
        resolve(res.data);
      } catch (error) {
        reject(error.response);
        this.handleError(error.response);
      }
    });
  }

  delete(uri, params, optional = null) {
    const url = Array.isArray(uri) ? uri.join("/") : uri;
    this.setAuthToken();
    return new Promise(async (resolve, reject) => {
      try {
        const res = await API.delete(url, { params });
        resolve(res.data);
      } catch (error) {
        reject(error.response);
        this.handleError(error.response, optional);
      }
    });
  }

  setAuth(token, user) {
    localStorage.setItem(AUTH_TOKEN_LIFF, token);
    localStorage.setItem(CURRENT_USER, user);
  }

  removeAuth() {
    localStorage.removeItem(AUTH_TOKEN_LIFF);
    localStorage.removeItem(CURRENT_USER);
  }

  getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN);
  }

  getLiffToken() {
    return localStorage.getItem(AUTH_TOKEN_LIFF);
  }

  getCurrentUser() {
    return localStorage.getItem(CURRENT_USER);
  }

  setAuthToken() {
    if (this.getAuthToken()) {
      API.defaults.headers.common["Access-Token"] = this.getAuthToken();
    } else {
      delete API.defaults.headers.common["Access-Token"];
    }
  }

  setAccessToken(accessToken) {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
  }

  setRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  async renewAccessToken(refresh_token) {
    const params = { refresh_token };
    const jwtToken = localStorage.getItem(JWT_TOKEN);
    return new Promise(async (resolve, reject) => {
      try {
        const isWeb = localStorage.getItem(IS_WEB);
        const res = await API.post(REFRESH_TOKEN_API_URL(isWeb), params, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        this.setAccessToken(res.data.data.access_token);
        this.setRefreshToken(res.data.data.refresh_token);
        resolve(res.data);
      } catch (error) {
        localStorage.removeItem(REFRESH_TOKEN);
        reject(error.response);
        // this.handleError(error.response);
      }
    });
  }

  async handleError(error) {
    if (!error) {
      return;
    }
    const message = error.data.errors[0].id;
    await messageService.show(message);
    if (Object.values(SURVEY_ROUTES).includes(window.location.pathname)) {
      if (error.status === 412) {
        window.location.reload();
      }
      return;
    }
    const isWeb = localStorage.getItem(IS_WEB);
    if (BOT_LINK.match(/^https?:\/\//) && !isWeb) {
      window.location.href = BOT_LINK;
      return;
    }
  }
}
