export const steps = [
  {
    id: 1,
    title: "",
    questions: [],
    answerId: null,
    disabled: false,
  },
  {
    id: 2,
    title: "",
    questions: [],
    answerId: null,
    disabled: false,
  },
  {
    id: 3,
    title: "",
    questions: [],
    answerId: null,
    disabled: false,
  },
  {
    id: 4,
    title: "",
    questions: [],
    answerId: null,
    disabled: false,
  },
  {
    id: 5,
    title: "",
    questions: [],
    answerId: null,
    disabled: false,
  },
  {
    id: 6,
    title: "",
    questions: [],
    answerId: null,
    disabled: false,
  },
  {
    id: 7,
    title: "",
    questions: [],
    answerId: null,
    disabled: false,
  },
  {
    id: 8,
    title: "",
    questions: [],
    answerId: null,
    disabled: false,
  },
  {
    id: 9,
    title: "",
    questions: [],
    answerId: null,
    disabled: false,
  },
  {
    id: 10,
    title: "",
    questions: [],
    answerId: null,
    disabled: false,
  },
];

export const DEFAULT_STORE = "UC1";

export const STORES_HAS_EXCLUDED_STYLE = ['TH1']

export const STORES_HAS_LIMITED_MAX_HEIGHT = ['KA1']

export const DEFAULT_BACKGROUND_COLOR = "#f7f8ff";

export const NOT_DEFAULT_BACKGROUND_COLOR = "#e5e5e5";

export const SUGI_BACKGROUND_COLOR = "#f6f6f6";

export const SURVEY_ROUTE = {
  survey: "/survey",
};

export const WEB_SURVEY_ROUTES = {
  webSurvey: "/web-survey",
  webSurveyResult: "/web-survey-result",
};

export const SURVEY_ROUTES = {
  ...SURVEY_ROUTE,
  ...WEB_SURVEY_ROUTES,
};

export const bodySizeMethods = {
  selfie: "SelfTakePhoto",
  camera: "TakePhotoByOther",
};

export const STORES_WITHOUT_PRODUCT_LINKS = ["SD1"];
