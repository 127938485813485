import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';


export class PageNotFound extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="error404">
        <h5 className="blue txt-center">ページが見つかりません</h5>
        <p>お探しのページは一時的にアクセスできない状態にあるか、存在しておりません。 </p>
      </div>
    );
  }
}

export default withRouter(PageNotFound);
