const MESSAGE_API_BODYGRAM = {
  101: {
    name: 'Success',
    message: '101 成功'
  },
  301: {
    name: 'NoExif',
    message: '301 ワーニング: EXIFがありません'
  },
  302: {
    name: 'FaceNotFound',
    message: '302 警告: 顔が認識できず、体組成推定に失敗しました。顔全体を出し(マスク等を外し)、再度撮影してください。'
  },
  303: {
    name: 'BodyComponentFailed',
    message: '303 警告: 体組成推定に失敗しました。Bodygram開発者へお問合せ下さい。'
  },
  304: {
    name: 'AvatarGenerationFailed',
    message: '304 警告: 3Dアバター生成に失敗しました。'
  },
  306: {
    name: 'BadPhotoWarning',
    message: '306 警告:  体が正しく認識できませんでした。カメラをウエストの高さに固定し、全身がフレーム内に収まるように撮影し直してください。'
  },
  701: {
    name: 'NoPerson',
    message: '701 エラー: 人物が検知できませんでした。ガイドに従い、撮影し直してください。'
  },
  702: {
    name: 'MultiPerson',
    message: '702 エラー: 複数の人物を検知しました。自分以外の人が写り込まないよう確認し、撮影し直してください。'
  },
  703: {
    name: 'TooDark',
    message: '703 エラー: 画像が暗すぎます。明るい場所で撮影し直してください。'
  },
  704: {
    name: 'TooBlight',
    message: '704 エラー: 画像が明るすぎます。光を抑えた場所で撮影し直してください。'
  },
  705: {
    name: 'Blur',
    message: '705 エラー: 画像がブレています。カメラを固定して撮影し直してください。'
  },
  706: {
    name: 'BadPhoto',
    message: '706 エラー:  体が正しく認識できませんでした。カメラをウエストの高さに固定し、全身がフレーム内に収まるように撮影し直してください。'
  },
  801: {
    name: 'InvalidImageFormat',
    message: '801 エラー: 画像フォーマットが不正です。アプリケーション開発元へお問合せ下さい。'
  },
  802: {
    name: 'ImageSize',
    message: '802 エラー: 画像サイズが不正です。アプリケーション開発元へお問合せ下さい。'
  },
  803: {
    name: 'InvalidGender',
    message: '803 エラー: 性別が不正です。アプリケーション開発元へお問合せ下さい。'
  },
  804: {
    name: 'LackOfImage',
    message: '804 エラー: 送信された画像が不足しています。ネットワークに接続していることを確認し、再度撮影し直してください。'
  },
  901: {
    name: 'ICCError',
    message: '901 エラー: システムエラー'
  },
  902: {
    name: 'PreDNNError',
    message: '902 エラー: システムエラー'
  },
  903: {
    name: 'DNNError',
    message: '903 エラー: システムエラー'
  },
  904: {
    name: 'RegressorError',
    message: '904 エラー: システムエラー'
  }
}

const MESSAGE_FOR_CSRF = '処理を中断しました'

const NO_INTERNET_MESSAGE = {
  title: 'ネットワークに接続できませんでした。',
  content: '通信環境をご確認の上、再度ご利用ください。'
}

export { MESSAGE_API_BODYGRAM, MESSAGE_FOR_CSRF, NO_INTERNET_MESSAGE }
