import React, { Component } from "react";
import Cleave from "cleave.js/react";
import APIService from "../../../service/api";
import messageService from "../../../service/messageService";
import Button from "../../modules/Button";
import Progress from "../../modules/Progress";
import store from "../../../service/store";
import APIBodygramService, {
  REFRESH_TOKEN,
} from "../../../service/APIBodygram";
import { searchToObject } from "../../../service/utils";
import { MESSAGE_FOR_CSRF } from "../../../../config/message";
import {
  USER_MEASUREMENT,
  ESTIMATION_ID,
  GENDER,
  NUMBER_FORMAT,
  STATE,
  IMG_URL,
  JWT_TOKEN,
  UUID,
  IS_WEB,
} from "../../../../config/config";
import BodyGramMark from "../../modules/BodyGramMark";

const MESSAGE_ERROR = {
  age: "Age error",
  gender: "",
  height: "height error",
  weight: "height error",
  tel: "Tel error",
  email: "Email error",
};

const REGEX = {
  tel: /^[0-9]{10,11}$/,
  email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
};

export class BodySizeInput extends Component {
  API = new APIService();
  APIBodygram = new APIBodygramService();

  constructor(props) {
    super(props);
    this.state = {
      form: {
        gender: {
          value: localStorage.getItem(GENDER) === "0" ? "male" : "female",
          error: "",
        },
        age: {
          value: "",
          min: 5,
          max: 150,
          error: "",
        },
        height: {
          value: "",
          min: 100,
          max: 250,
          error: "",
        },
        weight: {
          value: "",
          min: 30,
          max: 200,
          error: "",
        },
        tel: {
          value: "",
          error: "",
        },
        email: {
          value: "",
          error: "",
        },
        agree: {
          value: false,
        },
      },
      formValid: false,
      isShowBodygramSplash: false,
    };
  }

  async componentDidMount() {
    this.setState({ isShowBodygramSplash: true });
    setTimeout(() => {
      this.setState({ isShowBodygramSplash: false });
    }, 2000);
    window.scrollTo(0, 0);
    const { code, state } = searchToObject(this.props.location.search);
    if (state && state !== localStorage.getItem(STATE)) {
      await messageService.show(MESSAGE_FOR_CSRF);
      const uri = "https://www.google.com";
      if (uri.match(/^https?:\/\//)) {
        window.location.href = uri;
      }
      return;
    }
    history.replaceState({}, null, "body-size-input");
    if (code) {
      this.APIBodygram.requestAccessToken(code).then((res) => {
        this.API.renewAccessToken(res.refresh_token);
      });
    }
    const local = localStorage.getItem(USER_MEASUREMENT);
    if (local) {
      let getStore = JSON.parse(local);
      const { form } = this.state;
      form.gender.value = getStore.gender;
      form.age.value = getStore.age;
      form.height.value = getStore.height;
      form.weight.value = getStore.weight;
      form.tel.value = getStore.tel;
      form.email.value = getStore.email;
      this.setState(
        {
          form,
        },
        () => {
          this.checkInvalidForm();
        }
      );
    }
  }

  componentWillUnmount() {
    messageService.close();
  }

  requestPermission = () => {
    if (
      DeviceMotionEvent &&
      DeviceMotionEvent.requestPermission &&
      typeof DeviceMotionEvent.requestPermission === "function"
    ) {
      DeviceMotionEvent.requestPermission()
        .then((response) => {
          if (response == "granted") {
            window.addEventListener("devicemotion", (e) => {
              // do something with e
            });
          }
        })
        .catch(console.error);
    }
    if (
      DeviceOrientationEvent &&
      DeviceOrientationEvent.requestPermission &&
      typeof DeviceOrientationEvent.requestPermission === "function"
    ) {
      DeviceOrientationEvent.requestPermission()
        .then((response) => {
          if (response == "granted") {
            window.addEventListener("deviceorientation", (e) => {
              // do something with e
            });
          }
        })
        .catch(console.error);
    }
  };

  handleSubmit = async () => {
    if (this.state.formValid) {
      const { age, height, weight, gender, tel, email } = this.state.form;
      const userMeasurement = {
        height: +height.value,
        weight: +weight.value,
        age: +age.value,
        gender: gender.value,
        tel: tel.value, 
        email: email.value
      };
      const params = {
        phone_number: tel.value,
        email: email.value,
      };
      const jwt_token = localStorage.getItem(JWT_TOKEN);
      const uuid = localStorage.getItem(UUID, uuid);
      // Save activity logs into Sever
      const isWeb = localStorage.getItem(IS_WEB);
      await this.API.post(
        `api/v1/${isWeb ? "web/guests" : "users"}/logs`,
        {
          height: userMeasurement.height || null,
          weight: userMeasurement.weight || null,
          waist: null,
          waist_input: null,
          tigh: null,
          hip: null,
          product_name: null,
          product_size: null,
          estimate_id: null,
          uuid,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      const res = await this.API.post(
        `/api/v1/${isWeb ? "web/guests" : "users"}/body-gram/register`,
        params,
        { headers: { Authorization: `Bearer ${jwt_token}` } },
        { customError: true }
      );
      if (res?.data) {
        this.API.setAccessToken(res.data.access_token);
        this.API.setRefreshToken(res.data.refresh_token);
      }
      localStorage.setItem(USER_MEASUREMENT, JSON.stringify(userMeasurement));
      store[USER_MEASUREMENT] = userMeasurement;
      const refresh_token = localStorage.getItem(REFRESH_TOKEN);
      if (refresh_token) {
        this.API.renewAccessToken(refresh_token).then((_) => {
          this.APIBodygram.getEstimationID(userMeasurement).then((res) => {
            localStorage.setItem(ESTIMATION_ID, res.estimation_id);
            this.props.history.push("/sound-settings");
          });
        });
      }
    }
  };

  checkInvalidForm() {
    const { age, height, weight, tel, email, agree } = this.state.form;
    const value =
      age.value &&
      height.value &&
      weight.value &&
      (tel.value || email.value) &&
      agree.value;
    const error =
      age.error || height.error || weight.error || tel.error || email.error;
    this.setState({
      formValid: value && !error,
    });
  }

  handleChange(value, name) {
    let error = "";
    const field = this.state.form[name];
    const regex = new RegExp("^\\d+$");
    if (
      (regex.test(value) && field.max >= value && field.min <= value) ||
      (["tel", "email"].includes(name) &&
        (!value || REGEX?.[name]?.test(value)))
    ) {
      error = "";
    } else {
      error = MESSAGE_ERROR[name];
    }
    this.setState(
      (state) => {
        const form = state.form;
        form[name] = { ...form[name], value, error };
        return { form };
      },
      () => this.checkInvalidForm()
    );
  }

  handleChangeCheckbox() {
    this.setState(
      (state) => {
        const form = state.form;
        form.agree.value = !form.agree.value;
        return { form };
      },
      () => this.checkInvalidForm()
    );
  }

  render() {
    const { formValid, form, isShowBodygramSplash } = this.state;
    return (
      <>
        {isShowBodygramSplash ? (
          <BodyGramMark />
        ) : (
          <div className="body-size-input">
            <section className="bodygram-header">
              <Progress process={2} />
              <p className="title-sm">最適サイズ診断</p>
              <h1 className="bodygram-header-title">2.情報の入力</h1>
              <p className="bodygram-header-desc">
                おむつ利用者の基本情報を入力してください。基本情報をもとに画像を分析し、正確な結果を算出します。
              </p>
            </section>
            <section className="input-group">
              <ul>
                <li>
                  <label
                    className="size-input font-weight-bold"
                    htmlFor="select-menu"
                  >
                    性別
                    <select
                      className="custom-input"
                      id="select-menu"
                      name="gender"
                      placeholder="性別"
                      onChange={(e) =>
                        this.handleChange(e.target.value, e.target.name)
                      }
                      value={form.gender.value}
                    >
                      <option value="female">女性</option>
                      <option value="male">男性</option>
                    </select>
                    <img
                      className="ic-arrow-down"
                      src={`${IMG_URL}images/arrow_down_2.png`}
                      alt=""
                    />
                  </label>
                </li>
                <li>
                  <label
                    className="size-input size-input-age font-weight-bold"
                    htmlFor="age"
                  >
                    年齢
                    <Cleave
                      className={`custom-input ${
                        form["age"].error ? "input-warning" : ""
                      }`}
                      placeholder="年齢"
                      name="age"
                      id="age"
                      type="number"
                      pattern="\d*"
                      onChange={(e) =>
                        this.handleChange(e.target.value, e.target.name)
                      }
                      value={form.age.value}
                      options={NUMBER_FORMAT}
                    />
                    {form["age"].error && (
                      <p className="msg-error">
                        年齢を数字で5〜150で入力してください
                      </p>
                    )}
                  </label>
                </li>
                <li>
                  <label
                    className="size-input size-input-height font-weight-bold"
                    htmlFor="height"
                  >
                    身長
                    <Cleave
                      className={`custom-input ${
                        form["height"].error ? "input-warning" : ""
                      }`}
                      placeholder="身長"
                      id="height"
                      type="number"
                      pattern="\d*"
                      name="height"
                      onChange={(e) =>
                        this.handleChange(e.target.value, e.target.name)
                      }
                      value={form.height.value}
                      options={NUMBER_FORMAT}
                    />
                    {form["height"].error && (
                      <p className="msg-error">
                        身長を数字で100〜250で入力してください
                      </p>
                    )}
                  </label>
                </li>
                <li>
                  <label
                    className="size-input size-input-weight font-weight-bold"
                    htmlFor="weight"
                  >
                    体重
                    <Cleave
                      className={`custom-input ${
                        form["weight"].error ? "input-warning" : ""
                      }`}
                      placeholder="体重"
                      id="weight"
                      type="number"
                      pattern="\d*"
                      name="weight"
                      onChange={(e) =>
                        this.handleChange(e.target.value, e.target.name)
                      }
                      value={form.weight.value}
                      options={NUMBER_FORMAT}
                    />
                    {form["weight"].error && (
                      <p className="msg-error">
                        体重を数字で30〜200で入力してください
                      </p>
                    )}
                  </label>
                </li>
                <li className={form["weight"].error ? "pt-2" : ""}>
                  <label className="size-input font-weight-bold" htmlFor="tel">
                    携帯電話番号
                    <Cleave
                      className={`custom-input ${
                        form["tel"].error ? "input-warning" : ""
                      }`}
                      placeholder="例）09012345678"
                      type="number"
                      pattern="\d*"
                      name="tel"
                      onChange={(e) =>
                        this.handleChange(e.target.value, e.target.name)
                      }
                      value={form.tel.value}
                    />
                    {form["tel"].error && (
                      <p className="msg-error">
                        10桁もしくは11桁で入力してください。
                      </p>
                    )}
                  </label>
                </li>
                <li
                  className={`middle-line ${form["tel"].error ? "pt-4" : ""}`}
                >
                  <p>または</p>
                </li>
                <li className="pb-3">
                  <label
                    className="size-input font-weight-bold"
                    htmlFor="email"
                  >
                    メールアドレス
                    <input
                      className={`custom-input ${
                        form["email"].error ? "input-warning" : ""
                      }`}
                      placeholder="例）Shinji.tanaka@gmail.com"
                      name="email"
                      value={form.email.value}
                      onChange={(e) =>
                        this.handleChange(e.target.value, e.target.name)
                      }
                    />
                    {form["email"].error && (
                      <p className="msg-error">
                        有効なメールアドレスを入力してください。
                      </p>
                    )}
                  </label>
                </li>
                <li className={form["email"].error ? "pt-4" : ""}>
                  <label className="custom-checkbox">
                    <a href="https://bodygram.com/legal/japan_ja#box-d">
                      Bodygram利用許諾契約書
                    </a>
                    及び身体情報に関する
                    <a href="https://bodygram.com/legal/japan_ja#box-c">
                      個人情報の取り扱いについて
                    </a>
                    同意する
                    <input
                      type="checkbox"
                      name="agree"
                      checked={form.agree.value}
                      onChange={() => this.handleChangeCheckbox()}
                    />
                    <span className="custome-checkmark"></span>
                  </label>
                </li>
                <li>
                  <p className="note-text">
                    ※
                    未成年の方は、登録前に親権者または法定代理人の同意を得てください。
                  </p>
                </li>
              </ul>
            </section>
            <div>
              <Button
                className="btn btn-submit"
                content="次へ"
                onClick={() => {
                  this.requestPermission();
                  this.handleSubmit();
                }}
                isDisabled={!formValid}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default BodySizeInput;
