import React, { Component } from "react";
import { BODY_SIZE_METHOD, IMG_URL } from "../../../../config/config";
import BodyGramMark from "../../modules/BodyGramMark";
import Pickers from "../../modules/Pickers";
import Progress from "../../modules/Progress";
import APIService from "../../../service/api";
import cameraService from "../../../service/cameraService.js";
import { REFRESH_TOKEN } from "../../../service/APIBodygram";

export class BodySizeMeasureMethod extends Component {
  API = new APIService();
  timeout = null;

  constructor(props) {
    super(props);
    this.state = {
      bodySizeMethod: localStorage.getItem(BODY_SIZE_METHOD) || "SelfTakePhoto",
      isShownBodyGramSplash: false,
    };
  }

  componentDidMount() {
    document.querySelector("#app").classList.add("app-except-header");
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    document.querySelector("#app").classList.remove("app-except-header");
    this.timeout && clearTimeout(this.timeout);
    cameraService.close();
  }

  redirectToBodygramLogin = () => {
    this.props.history.push("/body-size-input");
  };

  redirectTo = () => {
    localStorage.setItem(BODY_SIZE_METHOD, this.state.bodySizeMethod);
    switch (this.state.bodySizeMethod) {
      case "SelfTakePhoto":
      case "TakePhotoByOther":
        const refresh_token = localStorage.getItem(REFRESH_TOKEN);
        if (refresh_token) {
          this.API.renewAccessToken(refresh_token)
            .then((_) => {
              this.setState(
                {
                  isShownBodyGramSplash: true,
                },
                () => {
                  this.timeout = setTimeout(() => {
                    this.props.history.push("body-size-input");
                  }, 2000);
                }
              );
            })
            .catch((_) => {
              this.redirectToBodygramLogin();
            });
        } else {
          this.redirectToBodygramLogin();
        }
        break;
      default:
        this.props.history.push("/waist-measurements");
        break;
    }
  };

  render() {
    const { isShownBodyGramSplash, bodySizeMethod } = this.state;
    const isTakenPhoto = ["SelfTakePhoto", "TakePhotoByOther"].includes(
      bodySizeMethod
    );
    return (
      <div className="body-size-get-method">
        <section>
          <Progress process={1} />
          <p className="title-sm large">最適サイズ診断</p>
          <h1 className="bodygram-header-title">1.計測方法の選択</h1>
          <p className="title-desc">
            からだの計測方法を選んでください。
            <br />
            撮影をすると、より正確に今の状態を測ることができます。
          </p>
        </section>
        <section>
          <Pickers
            name="method"
            valueDefault={bodySizeMethod}
            handleChange={(value) => this.setState({ bodySizeMethod: value })}
          />
        </section>
        <div
          onClick={async () => await cameraService.show(bodySizeMethod)}
          className={`camera-guide-wrapper flex-centered ${
            !isTakenPhoto ? "d-none-imp" : ""
          }`}
        >
          <img
            src={`${IMG_URL}images/camera-ic.svg`}
            alt="camera icon"
          />
          <span className="camera-label">詳しい撮影ガイドを見る</span>
        </div>
        <div className="mt-5">
          <button className="btn" onClick={() => this.redirectTo()}>
            診断を始める
          </button>
        </div>
        {isShownBodyGramSplash && <BodyGramMark />}
      </div>
    );
  }
}

export default BodySizeMeasureMethod;
