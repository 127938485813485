import React, { Component } from 'react'

const LIST_DEFAULT = [
  {
    content: '自分で撮影する',
    value: 'SelfTakePhoto'
  },
  {
    content: '誰かに撮影してもらう',
    value: 'TakePhotoByOther'
  },
  {
    content: 'ウエストサイズを手動で入力',
    value: 'ManuallyEnter'
  }
]
const VALUE_DEFAULT = 'SelfTakePhoto'

export class Pickers extends Component {

  constructor(props) {
    super(props)
    this.state = {
      value: this.props.valueDefault || VALUE_DEFAULT
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    newProps.valueDefault && this.setState({
      value: newProps.valueDefault
    })
  }

  handleChange = (value) => {
    this.setState({ value })
    this.props.handleChange && this.props.handleChange(value)
  }

  render() {
    let { list, name } = this.props;
    if (!list) {
      list = LIST_DEFAULT
    }
    const { value } = this.state

    return (
      <ul>
        {list && list.map((item, index) =>
          <li key={index}>
            <input
              id={item.value}
              type="radio"
              name={name}
              value={item.value}
              checked={value === item.value || !!item.checked}
              onChange={() => this.handleChange(item.value)}
            />
            <label className="custom-radio d-flex align-items-center font-weight-bold" htmlFor={item.value}>{item.content}</label>
          </li>
        )}
      </ul>
    )
  }
}

export default Pickers
