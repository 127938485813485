import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { IMG_URL } from "../../../config/config";
import { searchToObject } from "../../service/utils";
import {
  DEFAULT_STORE,
  STORES_WITHOUT_PRODUCT_LINKS,
} from "../../../constants/global";

const PRODUCT_TYPE = {
  pad: "パッド",
  pants: "パンツ",
};

export class WebSurveyResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      bannerImg: "",
      bannerParams: "",
      store: "",
    };
  }

  componentDidMount() {
    this.getResult();
    this.detectStore();
    this.handleEvents();
  }

  detectStore = () => {
    const { store } = searchToObject(this.props.location.search);
    if (store && store !== DEFAULT_STORE) {
      this.setState({ store });
    }
  };

  handleEvents = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    })
  };

  getResult = () => {
    const data = this.props.history?.location?.state?.data;
    if (typeof data !== 'object') {
      this.props.history.push(`/web-survey${window.location.search}`);
      return;
    }
    const dataKeys = Object.keys(data).sort((a, b) => b - a);
    const banner = data.banner_data;
    let products = [];
    if (dataKeys.includes("unicharm_products")) {
      dataKeys.map((dataKey) => {
        switch (dataKey) {
          case "unicharm_products":
            const resultKeys = Object.keys(data[dataKey]);
            resultKeys.map((resultKey, resultKeyInd) => {
              const result = data[dataKey][resultKey];
              result.products.map((item, ind) => {
                if (ind) {
                  const prevItem = result.products[ind - 1];
                  item.is_plus =
                    item.product_type !== prevItem.product_type
                      ? item.is_main_prod !== prevItem.is_main_prod
                        ? true
                        : item.product_type === PRODUCT_TYPE.pants &&
                          prevItem.product_type === PRODUCT_TYPE.pants
                        ? false
                        : undefined
                      : false;
                } else {
                  item.result_text = result.result_text;
                  if (resultKeyInd) {
                    item.is_plus = false;
                  }
                }
                products.push(item);
              });
            });
            break;
          case "store_products":
            const result = data[dataKey];
            result.map((item, ind) => {
              item.is_plus = ind ? item.product_type !== result[ind - 1].product_type : false;
              products.push(item);
            });
            break;
          default:
            break;
        }
      });
    } else {
      const resultKeys = Object.keys(data);
      resultKeys.map((resultKey, resultKeyInd) => {
        if (resultKey === "banner_data") {
          return;
        }
        const result = data[resultKey];
        result.products.map((item, ind) => {
          if (ind) {
            const prevItem = result.products[ind - 1];
            item.is_plus =
              item.product_type !== prevItem.product_type
                ? item.is_main_prod !== prevItem.is_main_prod
                  ? true
                  : item.product_type === PRODUCT_TYPE.pants &&
                    prevItem.product_type === PRODUCT_TYPE.pants
                  ? false
                  : undefined
                : false;
          } else {
            item.result_text = result.result_text;
            if (resultKeyInd) {
              item.is_plus = false;
            }
          }
          products.push(item);
        });
      });
    }
    const bannerParams = banner?.banner_params;
    this.setState({
      bannerImg: banner?.banner_img_url || "",
      products,
      ...(bannerParams
        ? {
            bannerParams: `gender=${bannerParams.gender}&token=${bannerParams.token}&uuid=${bannerParams.uuid}&isWeb=true`,
          }
        : {}),
    });
  };

  render() {
    const { products, bannerImg, bannerParams, store } = this.state;
    return (
      <div className="survey-result-page web">
        <header id="progress" className="progress">
          <div className="progress-status">
            <span className="count finished">診断結果</span>
          </div>
          <div className="progress-bar">
            <img
              src={`${IMG_URL}images/progress-bar.svg`}
              alt="progress bar image"
            />
            <div className={"progress-line step-done"}></div>
          </div>
        </header>
        <section
          className={`page-content ${!products.length ? "more-spacing" : ""}`}
        >
          {!!products.length ? (
            <>
              <h1 className="page-title">
                診断の結果、あなたにおすすめのユニ・チャーム商品はこちらです。
              </h1>
              <h4 className="page-count">{products.length}商品</h4>
              <ul className="product-list">
                {products.map((product) => {
                  return (
                    <li
                      key={`${product.id}`}
                      className={`product-item ${
                        product.is_plus ? "is-plus" : ""
                      } ${product.result_text ? "has-result-text" : ""}`}
                    >
                      {product?.is_plus === false && (
                        <span className="break-spacing">または</span>
                      )}
                      {product.result_text && (
                        <h3 className="result-text">{product.result_text}</h3>
                      )}
                      <div className="product-item-wrapper">
                        <img
                          src={`${IMG_URL}${product.image}`}
                          alt="product image"
                        />
                        <h5 className="product-pant">{product.product_type}</h5>
                        <p className="product-name">{product.name}</p>
                        <a
                          className={`btn btn-product ${
                            STORES_WITHOUT_PRODUCT_LINKS.includes(store)
                              ? "d-none-imp"
                              : ""
                          }`}
                          href={product.url}
                          target="_blank"
                        >
                          詳しく見る
                        </a>
                      </div>
                    </li>
                  );
                })}
              </ul>
              <div className={`banner-group`}>
                <div
                  className={`banner-image-wrapper ${
                    !bannerImg ? "d-none" : ""
                  }`}
                >
                  <div>
                    <img
                      className="object-fit-contain"
                      src={`${IMG_URL}images/banner-image-tooltip.png`}
                      alt="measurement banner image tooltip"
                    />
                  </div>
                  <img
                    className="banner-image"
                    src={bannerImg}
                    alt="measurement banner image"
                    onClick={() =>
                      this.props.history.push(`/introduction?${bannerParams}`)
                    }
                  />
                </div>
                <span className="break-spacing">または</span>
                <a
                  target="_blank"
                  className={`btn ${store ? "d-none-imp" : ""}`}
                  href="https://map.unicharm.co.jp/"
                >
                  <img src={`${IMG_URL}images/ic-note.svg`} alt="icon note" />
                  商品がある店舗をマップで探す
                </a>
                <button
                  className="btn btn-check"
                  onClick={() =>
                    this.props.history.push(
                      `/web-survey${window.location.search}`
                    )
                  }
                >
                  <img
                    src={`${IMG_URL}images/ic-checked.svg`}
                    alt="icon checked"
                  />
                  もう一度おすすめ商品診断をする
                </button>
              </div>
            </>
          ) : (
            <>
              <h1 className="page-title">
                申し訳ございません。ユニ・チャーム株式会社が提供する本サービスではお答えできない内容の可能性があります。
                <br />
                恐れ入りますが、商品に関するお問い合わせは、ユニ・チャームが提供する『ライフリーいきいきダイヤル』にお電話、メール、またはチャットにてご連絡ください。
              </h1>
              <div className="result-over-size">
                <section className="wrap-box live-dial">
                  <p className="title-dial">
                    <span>電話でのお問い合わせ</span>
                  </p>
                  <p className="dial-time">
                    <span>受付時間 10:00 ~ 16:00 (土日・祝日を除く)</span>
                  </p>
                  <p className="dial-phone">
                    <span className="wrap-phone-img">
                      <img src={`${IMG_URL}images/phone.png`} alt="" />
                    </span>
                    0120-0
                    <span className="phone-num">
                      4<span className="phone-text">よ</span>
                    </span>
                    <span className="phone-num">
                      1<span className="phone-text">い</span>
                    </span>
                    -
                    <span className="phone-num">
                      0<span className="phone-text">お</span>
                    </span>
                    <span className="phone-num">
                      6<span className="phone-text">む</span>
                    </span>
                    <span className="phone-num">
                      2<span className="phone-text">つ</span>
                    </span>
                  </p>
                  <a href="tel:0120041062">
                    <button className="btn btn-submit">電話をかける</button>
                  </a>
                </section>
                <section className="request-mail">
                  <p className="request-note">
                    <span className="">※</span>
                    新型コロナウイルス感染症の影響により、多くのお問い合わせをいただいており、お電話が繋がりにくい状況になっております。引き続き、メールやチャットにても受付を致しておりますので、ご活用頂けますようお願い申し上げます。
                  </p>
                </section>
                <section className="wrap-box live-dial mb-4">
                  <p className="title-dial yellow">
                    <span>メールでのお問い合わせ</span>
                  </p>
                  <p className="dial-time">
                    <span>受付時間 10:00 ~ 16:00 (土日・祝日を除く)</span>
                  </p>
                  <a href="https://www.support.unicharm.co.jp/mailcustomer/s/">
                    <button className="btn btn-submit">
                      メールで問い合わせる
                    </button>
                  </a>
                </section>
                <section className="wrap-box live-dial">
                  <p className="title-dial orange">
                    <span>チャットでのお問い合わせ</span>
                  </p>
                  <p className="dial-time">
                    受付時間 10:00 ~ 13:00　14:30 ~ 16:00
                    <br />
                    (土日・祝日を除く)
                  </p>
                  <a href="https://www.unicharm.co.jp/ja/contact-us.html">
                    <button className="btn btn-submit">
                      チャットで問い合わせる
                    </button>
                  </a>
                </section>
                <span className="break-spacing">または</span>
                <button
                  className="btn btn-check"
                  onClick={() =>
                    this.props.history.push(
                      `/web-survey${window.location.search}`
                    )
                  }
                >
                  <img
                    src={`${IMG_URL}images/ic-checked.svg`}
                    alt="icon checked"
                  />
                  もう一度おすすめ商品診断をする
                </button>
              </div>
            </>
          )}
        </section>
      </div>
    );
  }
}

export default withRouter(WebSurveyResult);
