import Cleave from "cleave.js/react";
import React, { Component } from "react";
import store from "../../../service/store";
import Button from "../../modules/Button";
import { NUMBER_FORMAT, WAIST_MEASUREMENT } from "../../../../config/config";

const MESSAGE_ERROR = {
  waist: "waist error",
};

export class WaistMeasurements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        waist: {
          value: "",
          min: 50,
          max: 125,
          error: "",
        },
      },
      formValid: false,
    };
  }

  componentDidMount() {
    document.querySelector("#app").classList.add("app-except-header");
    const waistMeasurement =
      store[WAIST_MEASUREMENT] || localStorage.getItem(WAIST_MEASUREMENT);
    if (waistMeasurement) {
      const { form } = this.state;
      form.waist.value = waistMeasurement;
      this.setState(
        {
          form,
        },
        () => {
          this.checkInvalidForm();
        }
      );
    }
  }

  componentWillUnmount() {
    document.querySelector("#app").classList.remove("app-except-header");
  }

  checkInvalidForm() {
    const { waist } = this.state.form;
    const value = waist.value;
    const error = waist.error;
    this.setState({
      formValid: value && !error,
    });
  }

  handlechange(value, name) {
    let error = "";
    const field = this.state.form[name];
    const regex = new RegExp("^\\d+$");
    if (regex.test(value) && field.max >= value && field.min <= value) {
      error = "";
    } else {
      error = MESSAGE_ERROR[name];
    }
    this.setState(
      (state) => {
        const form = state.form;
        form[name] = { ...form[name], value, error };
        return { form };
      },
      () => this.checkInvalidForm()
    );
  }

  handleSubmit = () => {
    if (this.state.formValid) {
      const waist = this.state.form.waist.value;
      store[WAIST_MEASUREMENT] = waist;
      localStorage.setItem(WAIST_MEASUREMENT, waist);
      this.props.history.push(`uploading-bodygram?waist=${+waist}`);
    }
  };

  render() {
    const { formValid, form } = this.state;

    return (
      <div className="waist-measurements">
        <section>
          <p className="title-sm">最適サイズ診断</p>
          <h1 className="bodygram-header-title">ウエストサイズ入力</h1>
          <p className="bodygram-header-desc">
            ウエストサイズ（おへそ周り）を入力してください。メジャーなどで計測すると、より正確な診断結果が出ます。
          </p>
        </section>
        <section>
          <label className="waist-input" htmlFor="">
            ウエスト（おへそ）周り
            <Cleave
              className={`custom-input ${
                form["waist"].error ? "input-warning" : ""
              }`}
              placeholder="ウエスト"
              pattern="\d*"
              type="number"
              name="waist"
              onChange={(e) => this.handlechange(e.target.value, e.target.name)}
              value={form.waist.value}
              options={NUMBER_FORMAT}
            />
            {form["waist"].error && (
              <p className="msg-error">
                ウエストを数字で50〜125で入力してください
              </p>
            )}
          </label>
        </section>
        <Button
          className="btn btn-submit"
          content="診断開始"
          onClick={this.handleSubmit}
          isDisabled={!formValid}
        />
      </div>
    );
  }
}

export default WaistMeasurements;
