import React, { Component } from "react";
import { render } from "react-dom";
import { bodySizeMethods } from "../../../constants/global";
import { IMG_URL } from "../../../config/config";

let resolve;

export default class CameraModal extends Component {
  mounted = false;

  static create() {
    const containerElement = document.createElement("div");
    document.body.appendChild(containerElement);
    return render(<CameraModal />, containerElement);
  }

  constructor(props) {
    super(props);
    this.state = {
      status: "",
    };
  }

  handleConfirm = () => {
    resolve(true);
    this.close();
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  show = (status) => {
    this.mounted && this.setState({ status });
    document.body.classList.add("overflow-hidden");
    return new Promise((res) => {
      resolve = res;
    });
  };

  close = () => {
    this.mounted && this.setState({ status: "" });
    document.body.classList.remove("overflow-hidden");
  };

  render() {
    const { status } = this.state;
    return (
      <>
        {status && (
          <div className="camera-error-box" onClick={this.handleConfirm}>
            <div
              className="camera-error-wrapper"
              onClick={(e) => e.stopPropagation()}
            >
              <button className="close-modal-btn" onClick={this.handleConfirm}>
                <img
                  src={`${IMG_URL}images/modal-close-ic.svg`}
                  alt="modal close icon"
                />
              </button>
              <h4 className="camera-title">撮影ガイド</h4>
              <ul className="camera-guide-list">
                <li className="camera-guide-item">
                  <h5 className="guide-note-order">①服装</h5>
                  <img
                    className="d-flex w-100"
                    src={`${IMG_URL}images/GC_step1.png`}
                    alt="guide camera step 1"
                  />
                  <ul className="guide-note-list">
                    <li className="guide-note-item">
                      帽子、サングラス、マスクは外す
                    </li>
                    <li className="guide-note-item">長い髪は束ねる</li>
                  </ul>
                  <img
                    className="d-flex w-100"
                    src={`${IMG_URL}images/GC_step2.png`}
                    alt="guide camera step 2"
                  />
                  <ul className="guide-note-list">
                    <li className="guide-note-item">
                      可能な限りシャツなどはズボンにイン
                    </li>
                    <li className="guide-note-item">上着類は脱ぐ</li>
                    <li className="guide-note-item">
                      できるだけ重ね着をせず、体のラインが分かりやすい服装
                    </li>
                    <li className="guide-note-item">
                      長袖は腕をまくり手首をみせる
                    </li>
                    <li className="guide-note-item">
                      ハイヒールや厚底の靴を避け、足先または靴が見えるようにする
                    </li>
                  </ul>
                </li>
                <li className="camera-guide-item">
                  <h5 className="guide-note-order">適さない服装</h5>
                  <img
                    className="d-flex w-100"
                    src={`${IMG_URL}images/GC_step3.png`}
                    alt="guide camera step 3"
                  />
                  <ul className="guide-note-list">
                    <li className="guide-note-item">全体に柄がついた服</li>
                    <li className="guide-note-item">
                      体のラインが分かりにくいロングスカート、ワイドパンツ、ルーズシャツなど、または大きなサイズの服
                    </li>
                  </ul>
                </li>
                <li className="camera-guide-item">
                  <h5 className="guide-note-order">②撮影場所</h5>
                  <img
                    className="d-flex w-100"
                    src={`${IMG_URL}images/GC_step4.png`}
                    alt="guide camera step 4"
                  />
                  <ul className="guide-note-list">
                    <li className="guide-note-item">暗所、逆光を避ける</li>
                    <li className="guide-note-item">
                      対象者以外の人、人型のものが写りこまないようにする
                    </li>
                  </ul>
                </li>
                <li className="camera-guide-item">
                  <h5 className="guide-note-order">③カメラの位置</h5>
                  {status === bodySizeMethods.selfie ? (
                    <>
                      <img
                        className="d-flex w-100"
                        src={`${IMG_URL}images/GC_step5a.png`}
                        alt="guide camera step 5a"
                      />
                      <ul className="guide-note-list">
                        <li className="guide-note-item">
                          カメラは、対象者の腰の高さくらいに設置する
                        </li>
                      </ul>
                      <img
                        className="d-flex w-100"
                        src={`${IMG_URL}images/GC_step6.png`}
                        alt="guide camera step 6"
                      />
                      <ul className="guide-note-list mb-0">
                        <li className="guide-note-item">
                          スマートフォンを立てかけ、水平垂直に固定できるように置く
                        </li>
                      </ul>
                    </>
                  ) : (
                    <>
                      <img
                        className="d-flex w-100"
                        src={`${IMG_URL}images/GC_step5b.png`}
                        alt="guide camera step 5b"
                      />
                      <ul className="guide-note-list mb-0">
                        <li className="guide-note-item">
                          撮影者は対象者と距離をとって、対象者の腰〜へその高さにカメラレンズがくるよう垂直に、スマートフォンを構える
                        </li>
                        <li className="guide-note-item">
                          対象者を画面内に収まるように立たせ、音声案内でシャッターが切られるまでスマートフォンを固定する
                        </li>
                      </ul>
                    </>
                  )}
                </li>
              </ul>
            </div>
          </div>
        )}
      </>
    );
  }
}
