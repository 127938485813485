const introData = [
  {
    id: 1,
    img: "UC_intro1.png",
    text: "正面と側面の2枚を撮影します。"
  },
  {
    id: 2,
    img: "UC_intro2.png",
    text: "身体のラインが分かりやすい服装で、手首が見える状態で撮影しましょう。"
  },
  {
    id: 3,
    img: "UC_intro3.png",
    text: "撮影中は音声でご案内します。音量の調整をおすすめします。"
  },
  {
    id: 4,
    img: "UC_intro4.png",
    text: "カメラ角度を調整する機能へのアクセスを求められます。許可してください。"
  },
]
export default introData;
