import axios from 'axios';
import messageService from './messageService';
import APIService from "./api";

// DOMAIN API
export const MEMBER_API_ENDPOINT = process.env.MIX_MEMBER_API_ENDPOINT
const AUTH_API_ENDPOINT = process.env.MIX_AUTH_API_ENDPOINT;
const ENDPOINT_BODYGRAM = process.env.MIX_ENDPOINT_BODYGRAM;
export const REDIRECT_URI = `${process.env.MIX_END_POINT}/body-size-input`;
// CONST CONFIG
const MEASUREMENT_ID = process.env.MIX_MEASUREMENT_ID;
export const CLIENT_ID = process.env.MIX_CLIENT_ID;
const GRANT_TYPE = 'authorization_code';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
const ESTIMATION_ID = 'ESTIMATION_ID';
export const REFRESH_TOKEN = 'refresh_token';

const API_BODYGRAM = axios.create({
  baseURL: ENDPOINT_BODYGRAM,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});

export default class APIBodygramService {

  requestAccessToken = async (code, options = {}) => {
    const params = new URLSearchParams()
    const code_verifier = localStorage.getItem('code_verifier')
    if (!code_verifier) {
      await messageService.show('未定義エラー')
    }
    params.append('grant_type', GRANT_TYPE)
    params.append('code', code)
    params.append('redirect_uri', REDIRECT_URI)
    params.append('client_id', CLIENT_ID)
    params.append('code_verifier', code_verifier)
    return new Promise(async (resolve, reject) => {
      try {
        const res = await API_BODYGRAM.post(AUTH_API_ENDPOINT, params);
        this.setAccessToken(res.data.access_token)
        localStorage.setItem(REFRESH_TOKEN, res.data.refresh_token)
        resolve(res.data);
      } catch (error) {
        reject(error.response);
        this.handleError(error.response, options);
      }
    })
  }

  renewAccessToken = async (refresh_token) => {
    const params = new URLSearchParams()
    params.append('grant_type', REFRESH_TOKEN)
    params.append(REFRESH_TOKEN, refresh_token)
    params.append('client_id', CLIENT_ID)
    return new Promise(async (resolve, reject) => {
      try {
        delete API_BODYGRAM.defaults.headers.common['Access-Token'];
        const res = await API_BODYGRAM.post(AUTH_API_ENDPOINT, params);
        this.setAccessToken(res.data.access_token)
        localStorage.setItem(REFRESH_TOKEN, res.data.refresh_token)
        resolve(res.data);
      } catch (error) {
        localStorage.removeItem(REFRESH_TOKEN)
        reject(error.response);
        // this.handleError(error.response);
      }
    })
  }

  // Request estimated size measurement
  getEstimationID({ height, weight, age, gender }, options = {}) {
    const uri = `${ENDPOINT_BODYGRAM}/estimation`
    const params = new URLSearchParams()
    params.append('height', height * 10) // cm => mm
    params.append('weight', weight * 1000) // kg => g
    params.append('age', age)
    params.append('gender', gender)
    params.append('client_id', CLIENT_ID)
    params.append('measurement_id', MEASUREMENT_ID)
    params.append('face_blur', true)
    this.addAccessTokenToHeaders()
    return new Promise(async (resolve, reject) => {
      try {
        const res = await API_BODYGRAM.post(uri, params);
        localStorage.setItem(ESTIMATION_ID, res.data.estimation_id);
        resolve(res.data);
      } catch (error) {
        reject(error.response);
        this.handleError(error.response, options);
      }
    })
  }

  // Sending captured images
  sendImageByEstimationID({ image, pattern }, config = null, options = {}) {
    const estimation_id = localStorage.getItem(ESTIMATION_ID);
    if (!estimation_id) return
    const formdata = new FormData()
    const uri = `${ENDPOINT_BODYGRAM}/estimation/${estimation_id}/image`
    formdata.append('image', image)
    formdata.append('pattern', pattern)
    formdata.append('client_id', CLIENT_ID)
    this.addAccessTokenToHeaders()
    return new Promise(async (resolve, reject) => {
      try {
        const res = await API_BODYGRAM.post(uri, formdata, {
          ...config,
          headers: { 'Content-type': 'multipart/form-data' }
        });
        resolve(res.data);
      } catch (error) {
        reject(error.response);
        if (!options.customError) {
          this.handleError(error.response, options);
        }
      }
    })
  }

  // Get captured images url
  getCapturedImagesUrl() {

  }

  // Get estimated size measurement result
  getEstimatedSizeMeasurementResult(options = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const estimation_id = localStorage.getItem(ESTIMATION_ID);
        if (!estimation_id) {
          reject('estimation_id is not found')
        }
        const uri = `/estimation/${estimation_id}?client_id=${CLIENT_ID}`
        this.addAccessTokenToHeaders()
        const res = await API_BODYGRAM.get(uri);
        resolve(res.data);
      } catch (error) {
        reject(error.response);
        this.handleError(error.response, options);
      }
    })
  }

  // START === ACCESS TOKEN ===
  getAccessToken() {
    // FIXME:
    return localStorage.getItem(ACCESS_TOKEN)
    // return "spSNSlJRg9l95wMUfys0lLPINcHaSzE11vmfd1SycXo.VDsdclQz0iBJ0kKDF1It-qfvtttWkhD0ykMtBsSMDig"
  }

  setAccessToken(accessToken) {
    localStorage.setItem(ACCESS_TOKEN, accessToken)
  }

  addAccessTokenToHeaders() {
    if (this.getAccessToken()) {
      API_BODYGRAM.defaults.headers.common['Access-Token']
        = this.getAccessToken()
    } else {
      delete API_BODYGRAM.defaults.headers.common['Access-Token'];
    }
  }
  // END === ACCESS TOKEN ===

  handleError = async (error, options = {}) => {
    if (error) {
      const message = error.data.message || error.data.error
      if (!options.isNotShowError) {
        await messageService.show(message)
      }
      switch (error.status) {
        case 400:
          break;
        case 401:
          const refresh_token = localStorage.getItem(REFRESH_TOKEN)
          if (refresh_token) {
            const API = new APIService();
            API.renewAccessToken(refresh_token)
              .catch(_ => { window.location.href = '/body-size-input' })
          } else {
            window.location.href = '/body-size-input'
          }
          // generateCodeChallenge().then(({ code_verifier, code_challenge, state, nonce }) => {
          //   const uri = `${MEMBER_API_ENDPOINT}/entry?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=${state}&nonce=${nonce}&code_challenge=${code_challenge}`
          //   if (uri.match(/^https?:\/\//)) {
          //     window.location.href = uri
          //   }
          // })
          break;
        case 403:
          break;
        case 404:
          break;
        case 422:
          break;
        case 500:
          break;
        default:
          break;
      }
    }
  }
}
